import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { getSortState, JhiItemCount, JhiPagination, TextFormat, Translate, translate } from 'react-jhipster';
import { Link, Route, useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, Table, UncontrolledTooltip } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import {
  findCustomAttributeValueByKey,
  getFiltersForCustomAttributes,
  overridePaginationStateWithQueryParams,
} from 'app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';

import { faMagnifyingGlass, faSortDown, faSortUp, faUndo } from '@fortawesome/free-solid-svg-icons';
import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import Filter from 'app/shared/FilterComponent';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { EmployeeStatus } from 'app/shared/model/enumerations/employee-status.model';
import { getQueryParamsIfExists } from 'app/shared/util/filter-utils';
import { exportEntities, getEntities, searchEntities } from './employee.reducer';
import PaginatedDropdown from 'app/shared/components/pagination-dropdown';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import InteractionDrawer from '../orders/details-components/requested-users/drawer/interaction-drawer';
import ApplicationResourceDeleteDialog from '../application-resource/application-resource-delete-dialog';
import ApplicationResourceUpdate from '../application-resource/application-resource-update';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { handleSortIconsTable } from 'app/shared/util/table-icons';
import { APP_TABLE_TEXT_MAX_CHARS, AUTHORITIES } from 'app/config/constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { getApiConfigurations } from 'app/modules/administration/administration.reducer';
import CustomText from 'app/shared/components/customText/customText';
import ShowCustomAttributes from 'app/shared/components/customButtons/showCustomAttributes';
import { AuditEntityName } from 'app/shared/model/audit.model';
import AuditEntityModal from '../audit/audit-entity-modal';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import AuditModal from '../audit/audit-modal';

export const Employee = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const [auditModal, setAuditModal] = useState<boolean>(false);
  const [auditModal2, setAuditModal2] = useState<boolean>(false);
  const [entityId, setEntityId] = useState<string>('');
  const configuration = useAppSelector(state => state.administration.configuration);
  const allowManualEntityCreationProp = configuration?.apiConfigProps?.allowManualEntityCreation;

  const [search, setSearch] = useState('');
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const isHRAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.HR_ADMIN]));

  useEffect(() => {
    dispatch(getApiConfigurations());
  }, []);

  const handlePageSizeChange = size => {
    paginationState.activePage = 1;
    paginationState.itemsPerPage = size;
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query,
      })
    );
  };
  const [filterShown, setFilterShown] = useState<boolean>(true);
  const [query, setQuery] = useState<string>(getQueryParamsIfExists(location.search));

  const employeeList = useAppSelector(state => state.employee.entities);
  const loading = useAppSelector(state => state.employee.loading || state.customAttributeDefinition.loading);
  const totalItems = useAppSelector(state => state.employee.totalItems);
  const locale = useAppSelector(state => state.locale.currentLocale);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state =>
    state.customAttributeDefinition.entities?.filter(
      def => def.visibleInList && localStorage.getItem('TABLE_showCustomAttributes') === 'true'
    )
  );

  const toggleFilter = () => {
    if (filterShown) setQuery('');
    setFilterShown(!filterShown);
  };

  const getAllEntities = () => {
    if (search) {
      dispatch(
        searchEntities({
          query: search,
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        })
      );
    } else {
      dispatch(
        getEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query,
        })
      );
    }
  };

  const startSearching = e => {
    if (search) {
      setPaginationState({
        ...paginationState,
        activePage: 1,
      });
      dispatch(
        searchEntities({
          query: search,
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        })
      );
    }
    e.preventDefault();
  };

  const clear = () => {
    setSearch('');
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    dispatch(getEntities({}));
  };

  const handleSearch = event => setSearch(event.target.value);

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}&query=${query}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.EMPLOYEE));
  }, []);

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, search, query]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const exportTable = () => {
    dispatch(
      exportEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query,
      })
    );
  };

  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };

  const toggleAuditModal2 = (id?: string) => {
    if (id) {
      setEntityId(id);
    }
    setAuditModal(false);
    setAuditModal2(!auditModal2);
  };

  return (
    <React.Fragment>
      <Card className="jh-card h-100">
        <h2 id="employee-heading" data-cy="EmployeeHeading">
          <Translate contentKey="iamdentityApp.employee.home.title">Employees</Translate>
          <div className="d-flex justify-content-end">
            <ShowCustomAttributes />
            <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
              <FontAwesomeIcon icon="sync" spin={loading} className={'me-1'} />
              <Translate contentKey="iamdentityApp.employee.home.refreshListLabel">Refresh List</Translate>
            </Button>
            <Button className="me-2" color="light" onClick={exportTable} disabled={loading}>
              <FontAwesomeIcon icon="file-excel" spin={loading} />
              <Translate contentKey="entity.action.export">Export</Translate>
            </Button>
            <Button onClick={toggleAuditModal} color="light" className="me-2">
              <FontAwesomeIcon icon={faClockRotateLeft} />
              <Translate contentKey="entity.action.history">History</Translate>
            </Button>
            {(isAdmin || isHRAdmin) && allowManualEntityCreationProp && (
              <Link to="/employee/new" className="btn btn-success jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
                <FontAwesomeIcon icon="plus" />
                &nbsp;
                <Translate contentKey="iamdentityApp.employee.home.createLabel">Create new Employee</Translate>
              </Link>
            )}
          </div>
        </h2>
        <div className="table-responsive table-scroll-custom h-100">
          <Table responsive>
            <thead className="table-scroll-custom_thead">
              {filterShown && (
                <Filter
                  getEntities={setQuery}
                  columns={[
                    {
                      name: 'code',
                      type: 'string',
                      placeholder: translate(`iamdentityApp.employee.code`),
                    },
                    {
                      name: 'person',
                      type: 'string',
                      placeholder: translate(`iamdentityApp.employee.person`),
                    },
                    {
                      name: 'job',
                      type: 'string',
                      placeholder: translate(`iamdentityApp.employee.job`),
                    },
                    {
                      name: 'organizationUnit',
                      type: 'string',
                      placeholder: translate(`iamdentityApp.employee.organizationUnit`),
                    },
                    {
                      name: 'superior',
                      type: 'string',
                      placeholder: translate(`iamdentityApp.employee.superior`),
                    },
                    {
                      name: 'employeeStatus',
                      type: 'select',
                      values: [
                        { value: 'placeholder', label: '' },
                        ...Object.values(EmployeeStatus).map(value => ({
                          value,
                          label: translate(`iamdentityApp.EmployeeStatus.${value}`),
                        })),
                      ],
                    },
                    {
                      name: 'contractStartDate',
                      type: 'date',
                    },
                    {
                      name: 'contractEndDate',
                      type: 'date',
                    },
                    {
                      name: 'location',
                      type: 'string',
                      placeholder: translate(`iamdentityApp.employee.location`),
                    },
                    ...getFiltersForCustomAttributes(customAttributeDefinitions),
                  ]}
                />
              )}
              <tr>
                <th className="hand" onClick={sort('code')}>
                  <div className={'d-flex-filter'}>
                    <CustomText>
                      <Translate contentKey="iamdentityApp.employee.code">Code</Translate>
                    </CustomText>
                    {handleSortIconsTable('code')}
                  </div>
                </th>
                <th className="hand" onClick={sort('person.firstName')}>
                  <div className={'d-flex-filter'}>
                    <CustomText>
                      <Translate contentKey="iamdentityApp.employee.person">Person</Translate>
                    </CustomText>
                    {handleSortIconsTable('person.firstName')}
                  </div>
                </th>
                <th className="hand" onClick={sort('job')}>
                  <div className={'d-flex-filter'}>
                    <CustomText>
                      <Translate contentKey="iamdentityApp.employee.job">Job</Translate>
                    </CustomText>
                    {handleSortIconsTable('job')}
                  </div>
                </th>
                <th className="hand" onClick={sort('organizationUnit')}>
                  <div className={'d-flex-filter'}>
                    <CustomText>
                      <Translate contentKey="iamdentityApp.employee.organizationUnit">Organization Unit</Translate>
                    </CustomText>
                    {handleSortIconsTable('organizationUnit')}
                  </div>
                </th>
                <th className="hand" onClick={sort('superior')}>
                  <div className={'d-flex-filter'}>
                    <CustomText>
                      <Translate contentKey="iamdentityApp.employee.superior">Superior</Translate>
                    </CustomText>
                    {handleSortIconsTable('superior')}
                  </div>
                </th>
                <th className="hand" onClick={sort('employeeStatus')}>
                  <div className={'d-flex-filter'}>
                    <CustomText>
                      <Translate contentKey="iamdentityApp.employee.employeeStatus">Employee Status</Translate>
                    </CustomText>
                    {handleSortIconsTable('employeeStatus')}
                  </div>
                </th>
                <th className="hand" onClick={sort('contractStartDate')}>
                  <div className={'d-flex-filter'}>
                    <CustomText>
                      <Translate contentKey="iamdentityApp.employee.contractStartDate">Contract Start Date</Translate>
                    </CustomText>
                    {handleSortIconsTable('contractStartDate')}
                  </div>
                </th>
                <th className="hand" onClick={sort('contractEndDate')}>
                  <div className={'d-flex-filter'}>
                    <CustomText>
                      <Translate contentKey="iamdentityApp.employee.contractEndDate">Contract End Date</Translate>
                    </CustomText>
                    {handleSortIconsTable('contractEndDate')}
                  </div>
                </th>
                <th className="hand" onClick={sort('location')}>
                  <div className={'d-flex-filter'}>
                    <CustomText>
                      <Translate contentKey="iamdentityApp.employee.location">Location</Translate>
                    </CustomText>
                    {handleSortIconsTable('location')}
                  </div>
                </th>
                {customAttributeDefinitions?.map(cad => (
                  <th className="hand" key={cad.id}>
                    {cad.displayName}
                  </th>
                ))}
                <th className="hand">
                  {/*  <span>*/}
                  {/*    <Button size="sm" id="toggle-filter" color={filterShown ? 'secondary' : 'default'}*/}
                  {/*            onClick={toggleFilter}>*/}
                  {/*      <FontAwesomeIcon icon="magnifying-glass" />*/}
                  {/*    </Button>*/}
                  {/*  </span>*/}
                  {/*<UncontrolledTooltip placement="top" target="toggle-filter">*/}
                  {/*  <Translate contentKey={filterShown ? 'global.hideFilter' : 'global.showFilter'}>Show/Hide*/}
                  {/*    Filter</Translate>*/}
                  {/*</UncontrolledTooltip>*/}
                </th>
              </tr>
            </thead>
            <tbody>
              {employeeList.map((employee, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Link to={`/employee/${employee.id}`} color="link">
                      <CustomText>{employee.code}</CustomText>
                    </Link>
                  </td>
                  <td>
                    {employee.person ? (
                      <Link to={`/person/${employee.person.id}`}>
                        <CustomText>{employee.person?.firstName + ' - ' + employee.person?.name}</CustomText>
                      </Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {employee.job ? (
                      <Link to={`/job-definition/${employee.job.id}`}>
                        <CustomText>{employee.job.name}</CustomText>
                      </Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {employee.organizationUnit ? (
                      <Link to={`/organization-unit/${employee.organizationUnit.id}`}>
                        <CustomText>{employee.organizationUnit.name}</CustomText>
                      </Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {employee?.superior ? (
                      <Link to={`/employee/${employee.superior.id}`}>
                        <CustomText>{employee?.superior?.code + ' - ' + employee?.superior?.fullName}</CustomText>
                      </Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    <CustomText>{translate(`iamdentityApp.EmployeeStatus.${employee.employeeStatus}`)}</CustomText>
                  </td>
                  <td>
                    {employee.contractStartDate ? (
                      <TextFormat type="date" value={employee.contractStartDate} format={convertDateFormat(locale, 'date')} />
                    ) : null}
                  </td>
                  <td>
                    {employee.contractEndDate ? (
                      <TextFormat type="date" value={employee.contractEndDate} format={convertDateFormat(locale, 'date')} />
                    ) : null}
                  </td>
                  <td>
                    {employee.location ? (
                      <Link to={`/location/${employee.location.id}`}>
                        <CustomText>{employee.location.name}</CustomText>
                      </Link>
                    ) : (
                      ''
                    )}
                  </td>
                  {customAttributeDefinitions?.map(cad => (
                    <td key={cad.id}>
                      <CustomText>{findCustomAttributeValueByKey(cad, employee, locale)}</CustomText>
                    </td>
                  ))}
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`/employee/${employee.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />
                        {/*<span className="d-none d-md-inline">*/}
                        {/*  <Translate contentKey="entity.action.view">View</Translate>*/}
                        {/*</span>*/}
                      </Button>
                      {(isAdmin || isHRAdmin) && allowManualEntityCreationProp && (
                        <Button
                          tag={Link}
                          to={`/employee/${employee.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                          color="warning"
                          size="sm"
                          data-cy="entityEditButton"
                        >
                          <FontAwesomeIcon icon="pencil-alt" />
                          {/*<span className="d-none d-md-inline">*/}
                          {/*  <Translate contentKey="entity.action.edit">Edit</Translate>*/}
                          {/*</span>*/}
                        </Button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {(!employeeList || employeeList.length < 1) && !loading && (
            <div className="alert alert-warning mt-1">
              <Translate contentKey="iamdentityApp.employee.home.notFound">No Employees found</Translate>
            </div>
          )}
        </div>
        {totalItems ? (
          <div className={employeeList && employeeList.length > 0 ? '' : 'd-none'}>
            <div className="justify-content-center d-flex mt-2">
              <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
            </div>
            <div className="justify-content-center d-flex">
              <JhiPagination
                activePage={paginationState.activePage}
                onSelect={handlePagination}
                maxButtons={5}
                itemsPerPage={paginationState.itemsPerPage}
                totalItems={totalItems}
              />
              <PaginatedDropdown
                itemsPerPage={paginationState.itemsPerPage}
                handlePageSizeChange={handlePageSizeChange}
              ></PaginatedDropdown>
            </div>
          </div>
        ) : (
          ''
        )}
        <AuditEntityModal
          isOpen={auditModal}
          toggle={toggleAuditModal}
          entityName={AuditEntityName.EMPLOYEE}
          toggleAuditModal2={toggleAuditModal2}
          entityFieldName={'code'}
          entityDetailsName={'employee'}
          entityLink={'employee'}
        />
        <AuditModal isOpen={auditModal2} toggle={() => toggleAuditModal2()} entityId={entityId} entityName={AuditEntityName.EMPLOYEE} />
      </Card>
      <ErrorBoundaryRoutes>
        <Route path="modifyUserData">
          <Route
            index
            element={
              <InteractionDrawer>
                <ApplicationResourceUpdate />
              </InteractionDrawer>
            }
          />
        </Route>
      </ErrorBoundaryRoutes>
    </React.Fragment>
  );
};

export default Employee;
