import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Input, InputGroup, FormGroup, Form, Row, Col, Table } from 'reactstrap';
import { Translate, translate, getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IRecertificationSessionUserGroup } from 'app/shared/model/recertification-session-user-group.model';
import { searchEntities, getEntities } from './recertification-session-user-group.reducer';

export const RecertificationSessionUserGroup = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const [search, setSearch] = useState('');
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const recertificationSessionUserGroupList = useAppSelector(state => state.recertificationSessionUserGroup.entities);
  const loading = useAppSelector(state => state.recertificationSessionUserGroup.loading);
  const totalItems = useAppSelector(state => state.recertificationSessionUserGroup.totalItems);

  const getAllEntities = () => {
    if (search) {
      dispatch(
        searchEntities({
          query: search,
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        })
      );
    } else {
      dispatch(
        getEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        })
      );
    }
  };

  const startSearching = e => {
    if (search) {
      setPaginationState({
        ...paginationState,
        activePage: 1,
      });
      dispatch(
        searchEntities({
          query: search,
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        })
      );
    }
    e.preventDefault();
  };

  const clear = () => {
    setSearch('');
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    dispatch(getEntities({}));
  };

  const handleSearch = event => setSearch(event.target.value);

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, search]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  return (
    <div>
      <h2 id="recertification-session-user-group-heading" data-cy="RecertificationSessionUserGroupHeading">
        <Translate contentKey="iamdentityApp.recertificationSessionUserGroup.home.title">Recertification Session User Groups</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />
            <Translate contentKey="iamdentityApp.recertificationSessionUserGroup.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link
            to="/recertification-session-user-group/new"
            className="btn btn-success jh-create-entity"
            id="jh-create-entity"
            data-cy="entityCreateButton"
          >
            <FontAwesomeIcon icon="plus" />
            <Translate contentKey="iamdentityApp.recertificationSessionUserGroup.home.createLabel">
              Create new Recertification Session User Group
            </Translate>
          </Link>
        </div>
      </h2>
      <Row>
        <Col sm="12">
          <Form onSubmit={startSearching}>
            <FormGroup>
              <InputGroup>
                <Input
                  type="text"
                  name="search"
                  defaultValue={search}
                  onChange={handleSearch}
                  placeholder={translate('iamdentityApp.recertificationSessionUserGroup.home.search')}
                />
                <Button className="input-group-addon">
                  <FontAwesomeIcon icon="search" />
                </Button>
                <Button type="reset" className="input-group-addon" onClick={clear}>
                  <FontAwesomeIcon icon="trash" />
                </Button>
              </InputGroup>
            </FormGroup>
          </Form>
        </Col>
      </Row>
      <div className="table-responsive">
        {recertificationSessionUserGroupList && recertificationSessionUserGroupList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand id-size" onClick={sort('id')}>
                  <Translate contentKey="iamdentityApp.recertificationSessionUserGroup.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('revoked')}>
                  <Translate contentKey="iamdentityApp.recertificationSessionUserGroup.revoked">Revoked</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="iamdentityApp.recertificationSessionUserGroup.recertificationSession">
                    Recertification Session
                  </Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="iamdentityApp.recertificationSessionUserGroup.employee">Employee</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="iamdentityApp.recertificationSessionUserGroup.userGroup">User Group</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="iamdentityApp.recertificationSessionUserGroup.order">Order</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="iamdentityApp.recertificationSessionUserGroup.recertificationSessionUserIdentity">
                    Recertification Session User Identity
                  </Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {recertificationSessionUserGroupList.map((recertificationSessionUserGroup, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button
                      tag={Link}
                      to={`/recertification-session-user-group/${recertificationSessionUserGroup.id}`}
                      color="link"
                      size="sm"
                    >
                      {recertificationSessionUserGroup.id}
                    </Button>
                  </td>
                  <td>{recertificationSessionUserGroup.revoked ? 'true' : 'false'}</td>
                  <td>
                    {recertificationSessionUserGroup.recertificationSession ? (
                      <Link to={`/recertification-session/${recertificationSessionUserGroup.recertificationSession.id}`}>
                        {recertificationSessionUserGroup.recertificationSession.name}
                      </Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {recertificationSessionUserGroup.employee ? (
                      <Link to={`/employee/${recertificationSessionUserGroup.employee.id}`}>
                        {recertificationSessionUserGroup.employee.code}
                      </Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {recertificationSessionUserGroup.userGroup ? (
                      <Link to={`/user-group/${recertificationSessionUserGroup.userGroup.id}`}>
                        {recertificationSessionUserGroup.userGroup.id}
                      </Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {recertificationSessionUserGroup.order ? (
                      <Link to={`/orders/${recertificationSessionUserGroup.order.id}`}>{recertificationSessionUserGroup.order.id}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {recertificationSessionUserGroup.recertificationSessionUserIdentity ? (
                      <Link
                        to={`/recertification-session-user-identity/${recertificationSessionUserGroup.recertificationSessionUserIdentity.id}`}
                      >
                        {recertificationSessionUserGroup.recertificationSessionUserIdentity.id}
                      </Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button
                        tag={Link}
                        to={`/recertification-session-user-group/${recertificationSessionUserGroup.id}`}
                        color="info"
                        size="sm"
                        data-cy="entityDetailsButton"
                      >
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/recertification-session-user-group/${recertificationSessionUserGroup.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="warning"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/recertification-session-user-group/${recertificationSessionUserGroup.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="iamdentityApp.recertificationSessionUserGroup.home.notFound">
                No Recertification Session User Groups found
              </Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={recertificationSessionUserGroupList && recertificationSessionUserGroupList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex mt-2">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default RecertificationSessionUserGroup;
