import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IExpressionEntity } from 'app/shared/model/expression-entity.model';
import React, { useEffect, useState } from 'react';
import { Translate } from 'react-jhipster';
import { Badge, Button, Col, Row } from 'reactstrap';
import { Aggregator, Condition, Constant, Field, Function, Node, NodeBlock, Operator } from './classes';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import AggregatorComponent from './components/aggregator.component';
import ConditionComponent from './components/condition.component';
import ConstantComponent from './components/constant.component';
import FieldComponent from './components/field.component';
import FunctionComponent from './components/function.component';
import NodeBlockComponent from './components/node-block.component';
import OperatorComponent from './components/operator.component';
import { getExpressionFields } from './expression-builder.reducer';
import './style.scss';
import { IExpressionBuilderProps } from './types';
import { addFields, createTree, newNodeByNodeTypeOrValueType } from './utils';
import DataTransfer from 'app/shared/components/dataTransfer/dataTransfer';

export const ExpressioNBuilderContext = React.createContext({
  tree: {} as Node,
  setTree: (value: Node) => {},
});

export const renderNode = (node: Node) => {
  if (node instanceof NodeBlock) {
    return <NodeBlockComponent key={node.id} node={node} />;
  } else if (node instanceof Operator) {
    return <OperatorComponent key={node.id} node={node} />;
  } else if (node instanceof Aggregator) {
    return <AggregatorComponent key={node.id} node={node} />;
  } else if (node instanceof Field) {
    return <FieldComponent key={node.id} node={node} />;
  } else if (node instanceof Constant) {
    return <ConstantComponent key={node.id} node={node} />;
  } else if (node instanceof Function) {
    return <FunctionComponent key={node.id} node={node} />;
  } else if (node instanceof Condition) {
    return <ConditionComponent key={node.id} node={node} />;
  }

  return <Badge color="danger">ERROR</Badge>;
};

export const ExpressionBuilder = ({
  initialState,
  restrictedValueType,
  onChange,
  exportType = 'JSON',
  entityKey,
}: IExpressionBuilderProps) => {
  const dispatch = useAppDispatch();
  const expressionEntities: IExpressionEntity[] = useAppSelector(state => state.expression.entities);

  const [tree, setTree] = useState(null);

  const onSetTree = (value: Node) => {
    if (typeof value === 'string') {
      value = JSON.parse(value);
    }

    if (value) {
      value.parentValueType = restrictedValueType;
    }
    const newTree = createTree(value);
    setTree(newTree);
    if (exportType == 'JSON') {
      onChange(newTree == null ? null : JSON.stringify(newTree));
    } else {
      onChange(newTree);
    }
  };

  useEffect(() => {
    if (!expressionEntities.length) {
      dispatch(getExpressionFields({ entityKey }));
    }
  }, []);

  useEffect(() => {
    if (expressionEntities && expressionEntities.length) {
      addFields(expressionEntities);
    }
  }, [expressionEntities]);

  useEffect(() => {
    if (tree && tree.parentValueType != restrictedValueType) {
      setTree(null);
    }
  }, [restrictedValueType]);

  useEffect(() => {
    if (initialState) {
      setTree(
        createTree(typeof initialState === 'string' || initialState instanceof String ? JSON.parse(initialState.toString()) : initialState)
      );
    } else if (!initialState && tree) {
      setTree(null);
    }
  }, [initialState]);

  // Commented because they are unused
  // const onAddIf = () => {
  //   onSetTree(newNodeByNodeTypeOrValueType(NodeType._IF, restrictedValueType));
  // };
  // const onAddSwitch = () => {
  //   onSetTree(newNodeByNodeTypeOrValueType(NodeType._SWITCH, restrictedValueType));
  // };

  const onAddExpression = () => {
    onSetTree(newNodeByNodeTypeOrValueType(null, restrictedValueType));
  };

  const onRemoveClick = (n: Node) => {
    onSetTree(null);
  };

  if (tree) {
    return (
      <Row className="expression-builder m-0 p-0 gy-2">
        <Col md={2} className={'px-0'}>
          <DataTransfer export content={tree} />
        </Col>
        <Col md={12} className={'card-grey p-2'}>
          <Row className={'align-items-start'}>
            <Col md={1} className={'text-right'} style={{ width: '25px' }}>
              <FontAwesomeIcon
                icon="xmark"
                size="lg"
                onClick={() => onRemoveClick(tree)}
                style={{ cursor: 'pointer', paddingTop: '8px' }}
              />
            </Col>
            <Col md={11}>
              <ExpressioNBuilderContext.Provider value={{ tree, setTree: onSetTree }}>{renderNode(tree)}</ExpressioNBuilderContext.Provider>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  } else {
    return (
      <Row className={'mt-1 row-cols-4 expression-builder'}>
        <Col md={2}>
          <a onClick={onAddExpression} className={'btn btn-success p-0 w-100'}>
            <Row className={'m-0'}>
              <Col md={3} className={'col-icon'}>
                <FontAwesomeIcon icon={faPlus} />
              </Col>
              <Col md={9} className={'align-content-center px-0'}>
                <Translate contentKey="iamdentityApp.expressionBuilder.addExpression" />
              </Col>
            </Row>
          </a>
        </Col>
        <Col md={2}>
          <DataTransfer import setValue={onSetTree} />
        </Col>

        {/* Commented expressions IF and SWITCH */}
        {/* <Button onClick={onAddIf} color="primary">
             <Translate contentKey="iamdentityApp.expressionBuilder.addIf" />
           </Button> */}
        {/* <Button onClick={onAddSwitch} color="info">
             <Translate contentKey="iamdentityApp.expressionBuilder.addSwitch" />
           </Button> */}
      </Row>
    );
  }
};

export default ExpressionBuilder;
