import dayjs from 'dayjs';
import { EmployeeStatus } from 'app/shared/model/enumerations/employee-status.model';
import { IInAdditionalAttribute } from './in-additional-attribute.model';

export interface IInEmployee {
  id?: number;
  code?: string | null;
  contractStartDate?: string | null;
  contractEndDate?: string | null;
  employeeStatus?: EmployeeStatus | null;
  organizationUnitCode?: string | null;
  jobDefinitionInternalCode?: string | null;
  superiorEmployeeCode?: string | null;
  locationCode?: string | null;
  personName?: string | null;
  personFirstName?: string | null;
  personNationalIdentifier?: string | null;
  personPhoneNumber?: string | null;
  personCommunicationEmail?: string | null;
  personDateOfBirth?: string | null;
  personNationality?: string | null;
  additionalAttributes?: IInAdditionalAttribute | [];
}

export const defaultValue: Readonly<IInEmployee> = {};
