import { APP_GLOBAL_DATE_FORMAT, APP_GLOBAL_DATETIME_FORMAT } from 'app/config/constants';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import {
  DropdownItem,
  IAggregator,
  ICondition,
  IConstant,
  IField,
  IFunction,
  INode,
  INodeBlock,
  IOperator,
  NodeDescription,
  NodeType,
  ValueType,
} from './types';
import {
  AllValueTypes,
  Constants,
  getAlternativeItems,
  getAlternativesByNodeType,
  newNodeByNodeType,
  newNodeByNodeTypeOrValueType,
} from './utils';
import { ExpressionEntity } from 'app/shared/model/expression-entity.model';

export class Node implements INode {
  id: string;
  name: string;
  valueType: ValueType;
  acceptedValueTypes?: ValueType[];
  expressionEntity?: ExpressionEntity = ExpressionEntity.OBJECT;
  order: number;
  nodes: Node[];
  type: NodeType;
  _type: 'NODE' | 'SPEL';
  parentValueType?: ValueType;

  constructor(node: NodeDescription) {
    this.id = uuidv4();
    this.name = node?.name;
    this.valueType = node?.valueType;
    this.acceptedValueTypes = node?.acceptedValueTypes;
    this.expressionEntity = node?.expressionEntity;
    this.nodes = [];
  }

  private setOrder(order: number) {
    this.order = order;
  }

  public getAcceptedValueTypes() {
    return this.acceptedValueTypes ?? AllValueTypes;
  }

  public getAlternativeValueTypes() {
    return this.parentValueType ? [this.parentValueType] : AllValueTypes;
  }

  public orderNodes() {
    if (this.nodes.length) {
      this.nodes.forEach((n, index) => n.setOrder(index));
    }
  }

  public addChild(node: Node, position: number = null) {
    if (this.valueType) {
      node.parentValueType = this.valueType;
    }

    if (position != null) {
      this.nodes.splice(position, 0, node);
    } else {
      this.nodes.push(node);
    }
    this.orderNodes();
  }

  public addChildAt(node: Node, newNode: Node, position: number = null) {
    if (this.id == node.id) {
      node.addChild(newNode, position);
    } else {
      for (let i = 0; i < this.nodes.length; i++) {
        this.nodes[i].addChildAt(node, newNode, position);
      }
    }
  }

  public removeChild(node: Node) {
    let found = false;
    let newNodes = [];
    for (const n of this.nodes) {
      if (n.id === node.id) {
        found = true;
      } else {
        if (!found) {
          n.removeChild(node);
        }
        newNodes.push(n);
      }
    }
    this.nodes = newNodes;
    this.orderNodes();
  }

  public changeNode(node: Node, newNode: Node, keepChildren = false) {
    newNode.expressionEntity = this.expressionEntity;

    if (this.id == node.id) {
      if (keepChildren && node.type == newNode.type) {
        newNode.nodes = this.nodes;
        if (this.acceptedValueTypes) {
          newNode.acceptedValueTypes = this.acceptedValueTypes;
        }
      }

      // removes all properties of this before copying the new ones
      Object.keys(this).forEach(key => delete this[key]);
      Object.assign(this, newNode);
      this.orderNodes();
    } else {
      for (let i = 0; i < this.nodes.length; i++) {
        this.nodes[i].changeNode(node, newNode, keepChildren);
      }
    }
  }
}

export class Condition extends Node {
  nodesNo: number;
  value?: string;
  _sameTypeNodes: boolean;
  _possibleValueTypes: ValueType[];
  expressionEntity?: ExpressionEntity;

  constructor(node: ICondition) {
    super(node);
    this.type = NodeType._CONDITIONS;
    this.nodesNo = node.nodesNo;
    this._type = node._type;
    this._sameTypeNodes = node._sameTypeNodes;
    if (node._sameTypeNodes && node?.params[0]?.acceptedValueTypes) {
      this._possibleValueTypes = node.params[0].acceptedValueTypes;
      this.acceptedValueTypes = [node.params[0].acceptedValueTypes[0]];
    }

    node.params.forEach((param, index) => {
      const acceptedValueTypes = this.acceptedValueTypes ?? param.acceptedValueTypes;
      const newNode = this.genericNewChild(acceptedValueTypes, index == 0 ? param.acceptedValueTypes : null);
      newNode._type = node._type;
      newNode.expressionEntity = param.expressionEntity;
      this.addChild(newNode);
    });
  }

  public addChild(node: Node, position: number = null) {
    super.addChild(node, position);
    this.calculateSpel();
  }

  public changeNode(node: Condition, newNode: Condition, keepChildren = false) {
    // TODO: maybe add this.acceptedValueTypes.length as required condition
    // makes sure the node that will change is a child of this condition
    if (this._sameTypeNodes && this.id != node.id && this.nodes.map(n => n.id).includes(node.id)) {
      // makes sure the left part of the condition can be changed to all possible types
      if (this.nodes[0].id == node.id) {
        newNode.acceptedValueTypes = this._possibleValueTypes;
      } else {
        newNode.acceptedValueTypes = this.acceptedValueTypes;
      }

      if (!this.acceptedValueTypes.includes(newNode.valueType)) {
        this.acceptedValueTypes = [newNode.valueType];
        for (let i = 0; i < this.nodes.length; i++) {
          if (this.nodes[i].id != node.id && !this.acceptedValueTypes.includes(this.nodes[i].valueType)) {
            this.nodes[i] = this.genericNewChild(this.acceptedValueTypes);
          }
        }
      }
    }

    newNode.expressionEntity = this.expressionEntity;
    newNode.parentValueType = this.parentValueType;

    super.changeNode(node, newNode, keepChildren);
    this.calculateSpel();
  }

  private genericNewChild(acceptedValueTypes: ValueType[], acceptedValueTypesToKeep = null) {
    const newNode = newNodeByNodeType(NodeType._FIELDS, acceptedValueTypes);
    newNode.acceptedValueTypes = acceptedValueTypesToKeep ? acceptedValueTypesToKeep : acceptedValueTypes;
    newNode.expressionEntity = this.expressionEntity;
    return newNode;
  }

  // special case for PROPERTY_IN_LIST
  private calculateSpel() {
    if (this._type == 'SPEL' && this.nodes.length == 2) {
      const fieldNode = this.nodes[1] as Field;
      const textNode = this.nodes[0] as Constant;
      const property = fieldNode.value.split('.')[1];
      if (this.name === 'PROPERTY_IN_LIST') {
        this.value = `${fieldNode.entityName}s.?[${property}=='${textNode.value}']`;
      } else {
        this.value = `${fieldNode.entityName}s.?[${property}=='${textNode.value}']`;
      }
    }
  }

  public getAlternatives(): DropdownItem[] {
    const items: DropdownItem[] = [];

    items.push(getAlternativesByNodeType(NodeType._CONDITIONS));

    return items;
  }
}

export class Field extends Node {
  order: number;
  value: string;
  displayName: string;
  entityName: string;
  translatableEntityName: string;

  constructor(node: IField, order = 0) {
    super(node);
    this.type = NodeType._FIELDS;
    this.value = node?.value;
    this.order = order;
    this.displayName = node?.displayName;
    this.entityName = node?.entityName;
    this.translatableEntityName = node?.translatableEntityName;
  }

  public getAlternatives(): DropdownItem[] {
    return getAlternativeItems(
      this._type == 'SPEL'
        ? [NodeType._CONSTANTS, NodeType._FIELDS]
        : [NodeType._FIELDS, NodeType._CONSTANTS, NodeType._FUNCTIONS, NodeType._AGGREGATORS],
      this.getAcceptedValueTypes(),
      false,
      this.expressionEntity
    );
  }

  public changeNode(node: Function, newNode: Function, keepChildren = false) {
    if (this.id === node.id && node.acceptedValueTypes && node.acceptedValueTypes.length && !newNode.acceptedValueTypes) {
      newNode.acceptedValueTypes = node.acceptedValueTypes;
    }

    super.changeNode(node, newNode, keepChildren);
  }
}

export class Constant extends Node {
  value: string | number | boolean;
  expressionEntity?: ExpressionEntity;

  constructor(node: IConstant) {
    super(node);
    this.value = node.value;
    this.type = NodeType._CONSTANTS;
    this.expressionEntity = node.expressionEntity;
    if (node.name == Constants.CONST_DATE.name) {
      this.value = moment().format(APP_GLOBAL_DATE_FORMAT);
    } else if (node.name == Constants.CONST_DATETIME.name) {
      this.value = moment().format(APP_GLOBAL_DATETIME_FORMAT);
    }
  }

  public getAlternatives(): DropdownItem[] {
    return getAlternativeItems(
      this._type == 'SPEL'
        ? [NodeType._CONSTANTS, NodeType._FIELDS]
        : [NodeType._CONSTANTS, NodeType._FIELDS, NodeType._FUNCTIONS, NodeType._AGGREGATORS],
      this.getAcceptedValueTypes(),
      false,
      this.expressionEntity
    );
  }
}

export class NodeBlock extends Node {
  constructor(node: INodeBlock, valueType: ValueType = null) {
    super(node);
    this.type = node.type;
    if (!node?.valueType) {
      this.valueType = valueType;
    }

    if (node.requiredNodes && node.requiredNodes.length) {
      node.requiredNodes.forEach(requiredNode => {
        const newNode = newNodeByNodeType(requiredNode, [valueType]);
        if (newNode) {
          this.addChild(newNode);
        }
      });
    } else if (node.acceptedValueTypes) {
      let newNode = null;
      if (this.valueType) {
        newNode = newNodeByNodeTypeOrValueType(null, this.valueType);
      } else {
        for (const acceptedValue of node.acceptedValueTypes) {
          newNode = newNodeByNodeTypeOrValueType(null, acceptedValue);
          if (newNode) {
            break;
          }
        }
      }

      if (newNode) {
        this.addChild(newNode);
      }
    }
  }

  public getAlternatives(): DropdownItem[] {
    let items: DropdownItem[] = [];

    if (this.type == NodeType._IF || this.type == NodeType._SWITCH) {
      items = getAlternativeItems([], this.getAlternativeValueTypes());
    }

    return items;
  }

  public getChildItems(nodeType: NodeType): DropdownItem[] {
    let items: DropdownItem[] = [];

    if (nodeType == NodeType._SWITCH) {
      items = getAlternativeItems([NodeType._CASE], this.getAcceptedValueTypes());
    }

    return items;
  }
}

export class Aggregator extends Node {
  constructor(node: IAggregator) {
    super(node);
    this.type = NodeType._AGGREGATORS;
  }

  public getAlternatives(): DropdownItem[] {
    // TODO: inheritedAcceptedValueType, make sure it won't allow IF or SWITCH if used in a condition
    return getAlternativeItems(
      [NodeType._AGGREGATORS, NodeType._FUNCTIONS, NodeType._FIELDS, NodeType._CONSTANTS],
      this.getAlternativeValueTypes(),
      false,
      this.expressionEntity
    );
  }

  public getChildItems(): DropdownItem[] {
    return getAlternativeItems(
      [NodeType._AGGREGATORS, NodeType._FUNCTIONS, NodeType._FIELDS, NodeType._CONSTANTS, NodeType._IF, NodeType._SWITCH],
      this.getAcceptedValueTypes(),
      this.nodes.length > 0,
      this.expressionEntity
    );
  }
}

export class Operator extends Aggregator {
  constructor(node: IOperator) {
    super(node);
    this.type = NodeType._OPERATORS;
  }

  public getAlternatives(): DropdownItem[] {
    return getAlternativeItems([], this.getAlternativeValueTypes());
  }

  public getChildItems(): DropdownItem[] {
    return getAlternativeItems([], this.getAcceptedValueTypes(), this.nodes.length > 0);
  }
}

export class Function extends Node {
  nodesNo: number;
  paramsDescription: NodeDescription[];

  constructor(node: IFunction) {
    super(node);
    this.type = NodeType._FUNCTIONS;
    this.nodesNo = node.nodesNo;
    this.paramsDescription = node.params;

    for (let param of node.params) {
      let newNode = null;
      if (param.acceptedValueTypes[0] == ValueType.STRING) {
        newNode = new Constant(Constants.CONST_STRING);
      } else if (param.acceptedValueTypes[0] == ValueType.NUMBER) {
        newNode = new Constant(Constants.CONST_NUMBER);
      } else if (param.acceptedValueTypes[0] == ValueType.BOOLEAN) {
        newNode = new Constant(Constants.CONST_BOOLEAN);
      }

      if (newNode != null) {
        newNode.acceptedValueTypes = param?.acceptedValueTypes;
        newNode.expressionEntity = param?.expressionEntity;
      }

      this.addChild(newNode);
    }
  }

  public changeNode(node: Function, newNode: Function, keepChildren = false) {
    // makes sure the node that will change is a child of this condition
    if (this.id != node.id && this.nodes.map(n => n.id).includes(node.id)) {
      // makes sure the left part of the condition can be changed to all possible types
      newNode.acceptedValueTypes = node.acceptedValueTypes;
    }

    super.changeNode(node, newNode, keepChildren);
  }

  public getAlternatives(): DropdownItem[] {
    return getAlternativeItems(
      [NodeType._FUNCTIONS, NodeType._FIELDS, NodeType._CONSTANTS, NodeType._AGGREGATORS],
      this.getAcceptedValueTypes()
    );
  }
}
