import dayjs from 'dayjs';

export interface IIAMImports {
  id?: number;
  importType?: string;
  importSession?: string | null;
  source?: string;
  sourceType?: string;
  idName?: string;
  idValue?: string;
  status?: string | null;
  statusReason?: string | null;
  createdDate?: string;
}

export const defaultValue: Readonly<IIAMImports> = {};
