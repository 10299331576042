import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { translate } from 'react-jhipster';
import { Aggregator, Node } from '../classes';
import { ExpressioNBuilderContext, renderNode } from '../expression-builder';
import '../style.scss';
import { DropdownClickProps } from '../types';
import { newNodeByValueAndType } from '../utils';
import NodeComponent from './node.component';

interface AggregatorComponentProps {
  node: Aggregator;
}

const AggregatorComponent = ({ node }: AggregatorComponentProps) => {
  const { tree, setTree } = useContext(ExpressioNBuilderContext);

  const onRemoveClick = (node: Node) => {
    tree.removeChild(node);
    setTree(tree);
  };

  const onChangeClick = ({ value, type }: DropdownClickProps) => {
    const newNode = newNodeByValueAndType(value, type, node.valueType);
    newNode.acceptedValueTypes = node.acceptedValueTypes;
    newNode.expressionEntity = node.expressionEntity;
    newNode._type = node._type;
    tree.changeNode(node, newNode);
    setTree(tree);
  };

  const onAddClick = ({ value, type }: DropdownClickProps) => {
    const newNode = newNodeByValueAndType(value, type, node.valueType, node.nodes);
    newNode.acceptedValueTypes = node.acceptedValueTypes;
    newNode.expressionEntity = node.expressionEntity;
    newNode._type = node._type;
    tree.addChildAt(node, newNode);
    setTree(tree);
  };

  return (
    <React.Fragment>
      <div className="button-row">
        <NodeComponent onChange={onChangeClick} items={node.getAlternatives()} value={node.name} color="red">
          {translate(`iamdentityApp.expressionBuilder.${node.name}`)}
        </NodeComponent>
        <NodeComponent onChange={onAddClick} items={node.getChildItems()} type="add" />
      </div>
      <div className="children-div">
        {node.nodes.map(child => {
          return (
            <div key={`aggregator-child=${child.id}`} className="removable-row">
              <div className="remove-button-area">
                <FontAwesomeIcon className="remove-button" icon="xmark" size="lg" onClick={() => onRemoveClick(child)} />
              </div>
              {renderNode(child)}
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default AggregatorComponent;
