import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_DATE_FORMAT, APP_TABLE_TEXT_MAX_CHARS } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { getSortState, JhiItemCount, JhiPagination, TextFormat, Translate, translate } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, Table, UncontrolledTooltip } from 'reactstrap';
import { exportEntities, fetchEntityList, getEntities, searchEntities } from './ordered-specific-permission.reducer';

import { OperationType } from 'app/shared/model/enumerations/operation-type.model';

import Filter from 'app/shared/FilterComponent';
import {
  findCustomAttributeValueByKey,
  getFiltersForCustomAttributes,
  overridePaginationStateWithQueryParams,
} from 'app/shared/util/entity-utils';
import { getQueryParamsIfExists } from 'app/shared/util/filter-utils';

import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { OrderExecutionStatus } from 'app/shared/model/enumerations/order-execution-status.model';
import { PermissionGrantLevel } from 'app/shared/model/enumerations/permission-grant-level.model';
import { IOrderedSpecificPermission } from 'app/shared/model/ordered-specific-permission.model';
import PaginatedDropdown from 'app/shared/components/pagination-dropdown';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { handleSortIconsTable } from 'app/shared/util/table-icons';
import { IOrder } from 'app/shared/model/order.model';
import { OrderStatus } from 'app/shared/model/enumerations/order-status.model';
import { ModificationLevelType } from 'app/shared/model/enumerations/modification-level-type.model';
import { AppliedOnTypes } from 'app/shared/model/enumerations/applied-on-types.model';
import ShowCustomAttributes from 'app/shared/components/customButtons/showCustomAttributes';
import CustomText from 'app/shared/components/customText/customText';
import { AuditEntityName } from 'app/shared/model/audit.model';
import AuditEntityModal from '../audit/audit-entity-modal';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import AuditModal from '../audit/audit-modal';

interface IOrderedSpecificPermissionProps {
  defaultFilter?: string;
  order?: IOrder;
}

export const OrderedSpecificPermission = ({ defaultFilter, order }: IOrderedSpecificPermissionProps) => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const [auditModal, setAuditModal] = useState<boolean>(false);
  const [auditModal2, setAuditModal2] = useState<boolean>(false);
  const [entityId, setEntityId] = useState<string>('');
  const [filterShown, setFilterShown] = useState<boolean>(true);
  const [query, setQuery] = useState<string>(getQueryParamsIfExists(location.search));
  const [search, setSearch] = useState('');

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const handlePageSizeChange = size => {
    paginationState.activePage = 1;
    paginationState.itemsPerPage = size;
    dispatch(
      fetchEntityList({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query,
      })
    );
  };

  const orderedSpecificPermissionList: IOrderedSpecificPermission[] = useAppSelector(state => state.orderedSpecificPermission.entities);
  const totalItems = useAppSelector(state => state.orderedSpecificPermission.totalItems);
  const loading = useAppSelector(state => state.orderedSpecificPermission.loading || state.customAttributeDefinition.loading);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state =>
    state.customAttributeDefinition.entities?.filter(() => localStorage.getItem('TABLE_showCustomAttributes') === 'true')
  );
  const locale = useAppSelector(state => state.locale.currentLocale);

  const toggleFilter = () => {
    if (filterShown) setQuery('');
    setFilterShown(!filterShown);
  };

  /* Enable display of "Add a new Specific Permission on Order" button only if the following conditions are met:
- the order STATUS is Draft
<
- MODIFICATION LEVEL is "ADD_NEW" and APPLIED ON IS "PERMISSION"
OR
- MODIFICATION LEVEL is "DELETE_EXISTING" and APPLIED ON IS "PERMISSION"
OR
- MODIFICATION LEVEL is "MODIFY_EXISTING" and APPLIED ON IS "PERMISSION"
OR
- MODIFICATION LEVEL is "MODIFY_EXISTING" and APPLIED ON IS "USER"
>
 */
  const creatableSpecificPermission = (orderInit: IOrder) => {
    let result = false;
    if (orderInit && orderInit.status === OrderStatus.DRAFT) {
      if (
        (orderInit.orderType.modificationLevel === ModificationLevelType.ADD_NEW &&
          orderInit.orderType.appliedOn === AppliedOnTypes.PERMISSION) ||
        (orderInit.orderType.modificationLevel === ModificationLevelType.DELETE_EXISTING &&
          orderInit.orderType.appliedOn === AppliedOnTypes.PERMISSION) ||
        (orderInit.orderType.modificationLevel === ModificationLevelType.MODIFY_EXISTING &&
          orderInit.orderType.appliedOn === AppliedOnTypes.PERMISSION) ||
        (orderInit.orderType.modificationLevel === ModificationLevelType.MODIFY_EXISTING &&
          orderInit.orderType.appliedOn === AppliedOnTypes.USER)
      ) {
        result = true;
      }
    }
    return result;
  };

  /* Enable display of the EDIT button only if the following conditions are met:
- the order STATUS is Draft
<
- MODIFICATION LEVEL is "ADD_NEW" and APPLIED ON IS "USER"  and orderedUserIdentity exists and userIdentity is null
OR
- MODIFICATION LEVEL is "ADD_NEW" and APPLIED ON IS "GROUP" and orderedUserIdentity is null and userIdentity exists
OR
- MODIFICATION LEVEL is "MODIFY_EXISTING" and APPLIED ON IS "USER" and orderedUserIdentity exists and userIdentity is null
OR
- MODIFICATION LEVEL is "DELETE_EXISTING" and APPLIED ON IS "PERMISSION" and orderedUserIdentity is null and userIdentity exists
OR
- MODIFICATION LEVEL is "MODIFY_EXISTING" and APPLIED ON IS "PERMISSION" and orderedUserIdentity is null and userIdentity exists
OR
- MODIFICATION LEVEL is "MODIFY_EXISTING" and APPLIED ON IS "USER" and orderedUserIdentity is null and userIdentity exists
>
 */
  const editableSpecificPermission = (orderInit: IOrder, orderedSpecificPermission: IOrderedSpecificPermission) => {
    let result = false;
    if (orderInit && orderInit.status === OrderStatus.DRAFT) {
      if (
        (orderInit.orderType.modificationLevel === ModificationLevelType.ADD_NEW &&
          orderInit.orderType.appliedOn === AppliedOnTypes.USER &&
          orderedSpecificPermission.orderedUserIdentity &&
          !orderedSpecificPermission.userIdentity) ||
        (orderInit.orderType.modificationLevel === ModificationLevelType.ADD_NEW &&
          orderInit.orderType.appliedOn === AppliedOnTypes.PERMISSION &&
          !orderedSpecificPermission.orderedUserIdentity &&
          orderedSpecificPermission.userIdentity) ||
        (orderInit.orderType.modificationLevel === ModificationLevelType.MODIFY_EXISTING &&
          orderInit.orderType.appliedOn === AppliedOnTypes.USER &&
          orderedSpecificPermission.orderedUserIdentity &&
          !orderedSpecificPermission.userIdentity) ||
        (orderInit.orderType.modificationLevel === ModificationLevelType.DELETE_EXISTING &&
          orderInit.orderType.appliedOn === AppliedOnTypes.PERMISSION &&
          !orderedSpecificPermission.orderedUserIdentity &&
          orderedSpecificPermission.userIdentity) ||
        (orderInit.orderType.modificationLevel === ModificationLevelType.MODIFY_EXISTING &&
          orderInit.orderType.appliedOn === AppliedOnTypes.PERMISSION &&
          !orderedSpecificPermission.orderedUserIdentity &&
          orderedSpecificPermission.userIdentity) ||
        (orderInit.orderType.modificationLevel === ModificationLevelType.MODIFY_EXISTING &&
          orderInit.orderType.appliedOn === AppliedOnTypes.USER &&
          !orderedSpecificPermission.orderedUserIdentity &&
          orderedSpecificPermission.userIdentity)
      ) {
        result = true;
      }
    }
    return result;
  };

  /* Enable display of the DELETE button only if the following conditions are met:
- the order STATUS is Draft
<
- MODIFICATION LEVEL is "ADD_NEW" and APPLIED ON IS "PERMISSION" and the length of the ORDERED SPECIFIC PERMISSION is greater than 1 and orderedUserIdentity exists and userIdentity is null
OR
- MODIFICATION LEVEL is "ADD_NEW" and APPLIED ON IS "USER" and orderedUserIdentity exists and userIdentity is null
OR
- MODIFICATION LEVEL is "MODIFY_EXISTING" and APPLIED ON IS "USER" and orderedUserIdentity exists and userIdentity is null
OR
- MODIFICATION LEVEL is "DELETE_EXISTING" and APPLIED ON IS "PERMISSION" and the length of the ORDERED SPECIFIC PERMISSION is greater than 1 and orderedUserIdentity is null and userIdentity exists
OR
- MODIFICATION LEVEL is "MODIFY_EXISTING" and APPLIED ON IS "PERMISSION" and the length of the ORDERED SPECIFIC PERMISSION is greater than 1 and orderedUserIdentity is null and userIdentity exists
OR
- MODIFICATION LEVEL is "MODIFY_EXISTING" and APPLIED ON IS "USER" and orderedUserIdentity is null and userIdentity exists
>
 */
  const deletableSpecificPermission = (orderInit: IOrder, orderedSpecificPermission: IOrderedSpecificPermission) => {
    let result = false;
    const orderedSpecificPermissionListLength = orderedSpecificPermissionList.length;
    if (orderInit && orderInit.status === OrderStatus.DRAFT) {
      if (
        (orderInit.orderType.modificationLevel === ModificationLevelType.DELETE_EXISTING &&
          orderInit.orderType.appliedOn === AppliedOnTypes.PERMISSION &&
          orderedSpecificPermissionListLength > 1 &&
          !orderedSpecificPermission.orderedUserIdentity &&
          orderedSpecificPermission.userIdentity) ||
        (orderInit.orderType.modificationLevel === ModificationLevelType.ADD_NEW &&
          orderInit.orderType.appliedOn === AppliedOnTypes.PERMISSION &&
          orderedSpecificPermissionListLength > 1 &&
          orderedSpecificPermission.orderedUserIdentity &&
          !orderedSpecificPermission.userIdentity) ||
        (orderInit.orderType.modificationLevel === ModificationLevelType.ADD_NEW &&
          orderInit.orderType.appliedOn === AppliedOnTypes.USER &&
          orderedSpecificPermission.orderedUserIdentity &&
          !orderedSpecificPermission.userIdentity) ||
        (orderInit.orderType.modificationLevel === ModificationLevelType.MODIFY_EXISTING &&
          orderInit.orderType.appliedOn === AppliedOnTypes.USER &&
          orderedSpecificPermission.orderedUserIdentity &&
          !orderedSpecificPermission.userIdentity) ||
        (orderInit.orderType.modificationLevel === ModificationLevelType.MODIFY_EXISTING &&
          orderInit.orderType.appliedOn === AppliedOnTypes.PERMISSION &&
          orderedSpecificPermissionListLength > 1 &&
          !orderedSpecificPermission.orderedUserIdentity &&
          orderedSpecificPermission.userIdentity) ||
        (orderInit.orderType.modificationLevel === ModificationLevelType.MODIFY_EXISTING &&
          orderInit.orderType.appliedOn === AppliedOnTypes.USER &&
          !orderedSpecificPermission.orderedUserIdentity &&
          orderedSpecificPermission.userIdentity)
      ) {
        result = true;
      }
    }
    return result;
  };

  const getAllEntities = () => {
    const finalQuery = query ? `${defaultFilter}&${query}` : defaultFilter;

    if (search) {
      dispatch(
        searchEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query: finalQuery,
        })
      );
    } else {
      dispatch(
        fetchEntityList({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query: finalQuery,
        })
      );
    }
  };

  const startSearching = e => {
    if (search) {
      setPaginationState({
        ...paginationState,
        activePage: 1,
      });
      dispatch(
        searchEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query: search,
        })
      );
    }
    e.preventDefault();
  };

  const clear = () => {
    setSearch('');
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    dispatch(fetchEntityList({}));
  };

  const handleSearch = event => setSearch(event.target.value);

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}&query=${query}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.ORDERED_SPECIFIC_PERMISSION));
  }, []);

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, search, query]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };
  const exportTable = () => {
    dispatch(
      exportEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query,
      })
    );
  };

  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };

  const toggleAuditModal2 = (id?: string) => {
    if (id) {
      setEntityId(id);
    }
    setAuditModal(false);
    setAuditModal2(!auditModal2);
  };

  return (
    <Card className="jh-card h-100">
      <h2 id="ordered-specific-permission-heading" data-cy="OrderedSpecificPermissionHeading">
        <Translate contentKey="iamdentityApp.orderedSpecificPermission.home.title">Ordered Specific Permissions</Translate>
        <div className="d-flex justify-content-end">
          <ShowCustomAttributes />
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />
            <Translate contentKey="iamdentityApp.orderedSpecificPermission.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Button className="me-2" color="light" onClick={exportTable} disabled={loading}>
            <FontAwesomeIcon icon="file-excel" spin={loading} />
            <Translate contentKey="entity.action.export">Export</Translate>
          </Button>
          <Button onClick={toggleAuditModal} color="light" className="me-2">
            <FontAwesomeIcon icon={faClockRotateLeft} />
            <Translate contentKey="entity.action.history">History</Translate>
          </Button>
          {defaultFilter && creatableSpecificPermission(order) && (
            <Link to="add-new" className="btn btn-success jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
              <FontAwesomeIcon icon="plus" />
              <Translate contentKey="iamdentityApp.orderedSpecificPermission.home.createLabel">
                Create new Ordered Specific Permission
              </Translate>
            </Link>
          )}
        </div>
      </h2>
      <div className="table-responsive table-scroll-custom table-input-number">
        <Table responsive>
          <thead className="table-scroll-custom_thead">
            {filterShown && (
              <Filter
                getEntities={setQuery}
                columns={[
                  {
                    name: 'id',
                    type: 'string',
                    placeholder: translate('iamdentityApp.orderedSpecificPermission.id'),
                  },
                  {
                    name: 'orderId',
                    type: 'number',
                    placeholder: translate('iamdentityApp.orderedSpecificPermission.orderId'),
                  },
                  {
                    name: 'application',
                    type: 'string',
                    placeholder: translate('iamdentityApp.orderedSpecificPermission.application'),
                  },
                  {
                    name: 'orderedUserIdentity',
                    type: 'string',
                    placeholder: translate('iamdentityApp.orderedSpecificPermission.orderedUserIdentity'),
                  },
                  {
                    name: 'operationType',
                    type: 'select',
                    values: [
                      { value: 'placeholder', label: '' },
                      ...Object.values(OperationType).map(value => ({
                        value,
                        label: translate(`iamdentityApp.OperationType.${value}`),
                      })),
                    ],
                  },
                  {
                    name: 'permissionDefinition',
                    type: 'string',
                    placeholder: translate('iamdentityApp.orderedSpecificPermission.permissionDefinition'),
                  },
                  {
                    name: 'grantedBy',
                    type: 'select',
                    values: [
                      { value: 'placeholder', label: '' },
                      ...Object.values(PermissionGrantLevel).map(value => ({
                        value,
                        label: translate(`iamdentityApp.PermissionGrantLevel.${value}`),
                      })),
                    ],
                  },
                  {
                    name: 'executionStatus',
                    type: 'select',
                    values: [
                      { value: 'placeholder', label: '' },
                      ...Object.values(OrderExecutionStatus).map(value => ({
                        value,
                        label: translate(`iamdentityApp.OrderExecutionStatus.${value}`),
                      })),
                    ],
                  },
                  {
                    name: 'scheduleTime',
                    type: 'instant',
                  },
                  {
                    name: 'executionStartTime',
                    type: 'instant',
                  },
                  {
                    name: 'executionEndTime',
                    type: 'instant',
                  },
                  {
                    name: 'userIdentity',
                    type: 'string',
                    placeholder: translate('iamdentityApp.orderedSpecificPermission.userIdentity'),
                  },
                  ...getFiltersForCustomAttributes(customAttributeDefinitions),
                ]}
              />
            )}
            <tr>
              <th className="hand id-size" onClick={sort('id')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orderedSpecificPermission.id">ID</Translate>
                  </CustomText>
                  {handleSortIconsTable('id')}
                </div>
              </th>
              <th className="hand" onClick={sort('order')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orderedSpecificPermission.orders">Orders</Translate>
                  </CustomText>
                  {handleSortIconsTable('order')}
                </div>
              </th>
              <th className="hand" onClick={sort('orderedUserIdentity.application.name')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orderedUserIdentity.application">Application</Translate>
                  </CustomText>
                  {handleSortIconsTable('orderedUserIdentity.application.name')}
                </div>
              </th>
              <th className="hand" onClick={sort('orderedUserIdentity.userName')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orderedSpecificPermission.orderedUserIdentity">Ordered User Identity</Translate>
                  </CustomText>
                  {handleSortIconsTable('orderedUserIdentity.userName')}
                </div>
              </th>
              <th className="hand" onClick={sort('operationType')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orderedSpecificPermission.operationType">Operation Type</Translate>
                  </CustomText>
                  {handleSortIconsTable('operationType')}
                </div>
              </th>
              <th className="hand" onClick={sort('permissionDefinition')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orderedSpecificPermission.permissionDefinition">Permission Definition</Translate>
                  </CustomText>
                  {handleSortIconsTable('permissionDefinition')}
                </div>
              </th>
              <th className="hand" onClick={sort('grantedBy')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orderedSpecificPermission.grantedBy">Granted By</Translate>
                  </CustomText>
                  {handleSortIconsTable('grantedBy')}
                </div>
              </th>
              <th className="hand" onClick={sort('executionStatus')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orderedSpecificPermission.executionStatus">Execution Status</Translate>
                  </CustomText>
                  {handleSortIconsTable('executionStatus')}
                </div>
              </th>
              <th className="hand" onClick={sort('scheduleTime')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orderedSpecificPermission.scheduleTime">Schedule Time</Translate>
                  </CustomText>
                  {handleSortIconsTable('scheduleTime')}
                </div>
              </th>
              <th className="hand" onClick={sort('executionStartTime')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orderedSpecificPermission.executionStartTime">Execution Start Time</Translate>
                  </CustomText>
                  {handleSortIconsTable('executionStartTime')}
                </div>
              </th>
              <th className="hand" onClick={sort('executionEndTime')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orderedSpecificPermission.executionEndTime">Execution End Time</Translate>
                  </CustomText>
                  {handleSortIconsTable('executionEndTime')}
                </div>
              </th>
              <th className="hand" onClick={sort('userIdentity.userName')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orderedSpecificPermission.userIdentity">User Identity</Translate>
                  </CustomText>
                  {handleSortIconsTable('userIdentity.userName')}
                </div>
              </th>
              {customAttributeDefinitions?.map(cad => (
                <th key={cad.id}>
                  <div className={'d-flex-filter'}>
                    <CustomText>{cad.displayName}</CustomText>
                  </div>
                </th>
              ))}
              <th className="hand">
                {/*    <span>*/}
                {/*      <Button size="sm" id="toggle-filter" color={filterShown ? 'secondary' : 'default'} onClick={toggleFilter}>*/}
                {/*        <FontAwesomeIcon icon="magnifying-glass" />*/}
                {/*      </Button>*/}
                {/*    </span>*/}
                {/*  <UncontrolledTooltip placement="top" target="toggle-filter">*/}
                {/*    <Translate contentKey={filterShown ? 'global.hideFilter' : 'global.showFilter'}>Show/Hide*/}
                {/*      Filter</Translate>*/}
                {/*  </UncontrolledTooltip>*/}
              </th>
            </tr>
          </thead>
          <tbody>
            {orderedSpecificPermissionList.map((orderedSpecificPermission, i) => (
              <tr key={`entity-${i}`} data-cy="entityTable">
                <td>
                  <Link to={`/ordered-specific-permission/${orderedSpecificPermission.id}`} color="link">
                    {orderedSpecificPermission.id}
                  </Link>
                </td>
                <td>
                  {orderedSpecificPermission.order ? (
                    <Link to={`/orders/${orderedSpecificPermission.order.id}`}>{orderedSpecificPermission.order.id}</Link>
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  {orderedSpecificPermission?.orderedUserIdentity?.application ? (
                    <Link to={`/application/${orderedSpecificPermission?.orderedUserIdentity?.application.id}`}>
                      <CustomText>{orderedSpecificPermission?.orderedUserIdentity?.application.name}</CustomText>
                    </Link>
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  {orderedSpecificPermission.orderedUserIdentity ? (
                    <Link to={`/ordered-user-identity/${orderedSpecificPermission.orderedUserIdentity.id}`}>
                      <CustomText>{orderedSpecificPermission.orderedUserIdentity.userName}</CustomText>
                    </Link>
                  ) : (
                    ''
                  )}
                </td>
                <td>{translate(`iamdentityApp.OperationType.${orderedSpecificPermission.operationType}`)}</td>
                <td>
                  {orderedSpecificPermission.permissionDefinition ? (
                    <Link to={`/permission-definition/${orderedSpecificPermission.permissionDefinition.id}`}>
                      <CustomText>{orderedSpecificPermission.permissionDefinition.name}</CustomText>
                    </Link>
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  <CustomText>{translate(`iamdentityApp.PermissionGrantLevel.${orderedSpecificPermission.grantedBy}`)}</CustomText>
                </td>
                <td>
                  <CustomText>{translate(`iamdentityApp.OrderExecutionStatus.${orderedSpecificPermission.executionStatus}`)}</CustomText>
                </td>
                <td>
                  {orderedSpecificPermission.scheduleTime ? (
                    <TextFormat type="date" value={orderedSpecificPermission.scheduleTime} format={convertDateFormat(locale, 'instant')} />
                  ) : null}
                </td>
                <td>
                  {orderedSpecificPermission.executionStartTime ? (
                    <TextFormat
                      type="date"
                      value={orderedSpecificPermission.executionStartTime}
                      format={convertDateFormat(locale, 'instant')}
                    />
                  ) : null}
                </td>
                <td>
                  {orderedSpecificPermission.executionEndTime ? (
                    <TextFormat
                      type="date"
                      value={orderedSpecificPermission.executionEndTime}
                      format={convertDateFormat(locale, 'instant')}
                    />
                  ) : null}
                </td>
                <td>
                  {orderedSpecificPermission.userIdentity ? (
                    <Link to={`/user-identity/${orderedSpecificPermission.userIdentity.id}`}>
                      <CustomText>{orderedSpecificPermission.userIdentity.userName}</CustomText>
                    </Link>
                  ) : (
                    ''
                  )}
                </td>
                {customAttributeDefinitions?.map(cad => (
                  <td key={cad.id}>
                    <CustomText>{findCustomAttributeValueByKey(cad, orderedSpecificPermission, locale)}</CustomText>
                  </td>
                ))}
                <td className="text-end">
                  <div className="btn-group flex-btn-group-container">
                    {defaultFilter ? (
                      <React.Fragment>
                        <Button tag={Link} to={`view/${orderedSpecificPermission.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                          <FontAwesomeIcon icon="eye" />
                          {/*<span className="d-none d-md-inline">*/}
                          {/*    <Translate contentKey="entity.action.view">View</Translate>*/}
                          {/*  </span>*/}
                        </Button>
                        {editableSpecificPermission(order, orderedSpecificPermission) ? (
                          <Button
                            tag={Link}
                            to={`update/${orderedSpecificPermission.id}`}
                            color="primary"
                            size="sm"
                            data-cy="entityEditButton"
                          >
                            <FontAwesomeIcon icon="pencil-alt" />
                            {/*<span className="d-none d-md-inline">*/}
                            {/*    <Translate contentKey="entity.action.edit">Edit</Translate>*/}
                            {/*  </span>*/}
                          </Button>
                        ) : null}
                        {deletableSpecificPermission(order, orderedSpecificPermission) ? (
                          <Button
                            tag={Link}
                            to={`delete/${orderedSpecificPermission.id}`}
                            color="danger"
                            size="sm"
                            data-cy="entityDeleteButton"
                          >
                            <FontAwesomeIcon icon="trash" />
                            {/*<span className="d-none d-md-inline">*/}
                            {/*    <Translate contentKey="entity.action.delete">Delete</Translate>*/}
                            {/*  </span>*/}
                          </Button>
                        ) : null}
                      </React.Fragment>
                    ) : (
                      <Button
                        tag={Link}
                        to={`/ordered-specific-permission/${orderedSpecificPermission.id}`}
                        color="info"
                        size="sm"
                        data-cy="entityDetailsButton"
                      >
                        <FontAwesomeIcon icon="eye" />
                        {/*<span className="d-none d-md-inline">*/}
                        {/*    <Translate contentKey="entity.action.view">View</Translate>*/}
                        {/*  </span>*/}
                      </Button>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {(!orderedSpecificPermissionList || orderedSpecificPermissionList.length < 1) && !loading && (
          <div className="alert alert-warning mt-1">
            <Translate contentKey="iamdentityApp.orderedSpecificPermission.home.notFound">No Ordered Specific Permissions found</Translate>
          </div>
        )}
      </div>
      {totalItems ? (
        <div className={orderedSpecificPermissionList && orderedSpecificPermissionList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex mt-2">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
            <PaginatedDropdown itemsPerPage={paginationState.itemsPerPage} handlePageSizeChange={handlePageSizeChange}></PaginatedDropdown>
          </div>
        </div>
      ) : (
        ''
      )}
      <AuditEntityModal
        isOpen={auditModal}
        toggle={toggleAuditModal}
        entityName={AuditEntityName.ORDERED_SPECIFIC_PERMISSION}
        toggleAuditModal2={toggleAuditModal2}
        entityFieldName={'scheduleTime'}
        entityDetailsName={'orderedSpecificPermission'}
        entityLink={'ordered-specific-permission'}
      />
      <AuditModal
        isOpen={auditModal2}
        toggle={() => toggleAuditModal2()}
        entityId={entityId}
        entityName={AuditEntityName.ORDERED_SPECIFIC_PERMISSION}
      />
    </Card>
  );
};

export default OrderedSpecificPermission;
