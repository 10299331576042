import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Form, FormGroup, Label, Row, Spinner } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';

import { apiUrl as applicationUrl } from 'app/entities/application/application.reducer';
import { apiUrl as connectorCommandUrl } from 'app/entities/connector-command/connector-command.reducer';
import { apiUrl as iamSystemUrl } from 'app/entities/iam-system/iam-system.reducer';
import { apiUrl as orderedGroupUrl } from 'app/entities/ordered-group/ordered-group.reducer';
import { apiUrl as orderedRoleUrl } from 'app/entities/ordered-role/ordered-role.reducer';
import { apiUrl as orderedSpecificPermissionUrl } from 'app/entities/ordered-specific-permission/ordered-specific-permission.reducer';
import { apiUrl as orderedUserIdentityUrl } from 'app/entities/ordered-user-identity/ordered-user-identity.reducer';
import { apiUrl as ordersUrl } from 'app/entities/orders/orders.reducer';
import PaginatedReactSelect from 'app/shared/components/react-select/paginated-react-select';
import { OrderExecutionStatus } from 'app/shared/model/enumerations/order-execution-status.model';
import { useForm } from 'react-hook-form';
import { createEntity, getEntity, reset, updateEntity } from './technical-order-action.reducer';
import { ToaExecutionStage } from 'app/shared/model/enumerations/toa-execution-stage.model';
import navigateBack from 'app/shared/components/handlers/buttons/back';

export const TechnicalOrderActionUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { technicalOrderActionId: id } = useParams<'technicalOrderActionId'>();
  const isNew = id === undefined;

  const technicalOrderActionEntity = useAppSelector(state => state.technicalOrderAction.entity);
  const loading = useAppSelector(state => state.technicalOrderAction.loading);
  const updating = useAppSelector(state => state.technicalOrderAction.updating);
  const updateSuccess = useAppSelector(state => state.technicalOrderAction.updateSuccess);
  const orderExecutionStatusValues = Object.keys(OrderExecutionStatus);
  const toaExecutionStageValues = Object.keys(ToaExecutionStage);

  const handleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    formReset(defaultValues());
  }, [technicalOrderActionEntity]);

  const saveEntity = values => {
    values.executionStartTime = convertDateTimeToServer(values.executionStartTime);
    values.executionEndTime = convertDateTimeToServer(values.executionEndTime);

    const entity = {
      ...technicalOrderActionEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () => {
    const result = isNew
      ? {
          executionStartTime: displayDefaultDateTime(),
          executionEndTime: displayDefaultDateTime(),
        }
      : {
          executionStatus: 'NOT_APPLICABLE',
          executionStage: 'NOT_STARTED',
          ...technicalOrderActionEntity,
          executionStartTime: convertDateTimeFromServer(technicalOrderActionEntity.executionStartTime),
          executionEndTime: convertDateTimeFromServer(technicalOrderActionEntity.executionEndTime),
          connectorCommand: technicalOrderActionEntity?.connectorCommand,
          iamSystem: technicalOrderActionEntity?.iamSystem,
          application: technicalOrderActionEntity?.application,
          orderedUserIdentity: technicalOrderActionEntity?.orderedUserIdentity,
          orderedRole: technicalOrderActionEntity?.orderedRole,
          orderedGroup: technicalOrderActionEntity?.orderedGroup,
          orderedSpecificPermission: technicalOrderActionEntity,
          order: technicalOrderActionEntity?.order,
        };
    return result;
  };

  const {
    register,
    handleSubmit,
    control,
    reset: formReset,
  } = useForm({
    defaultValues: defaultValues(),
    mode: 'onChange',
  });

  return (
    <Card className="jh-card">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.technicalOrderAction.home.createOrEditLabel" data-cy="TechnicalOrderActionCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.technicalOrderAction.home.createOrEditLabel">
              Create or edit a TechnicalOrderAction
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading || updating ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <Form onSubmit={handleSubmit(saveEntity)}>
              {!isNew ? (
                <ValidatedField
                  register={register}
                  name="id"
                  required
                  readOnly
                  id="technical-order-action-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.technicalOrderAction.executionStatus')}
                id="technical-order-action-executionStatus"
                name="executionStatus"
                data-cy="executionStatus"
                type="select"
              >
                {orderExecutionStatusValues.map(orderExecutionStatus => (
                  <option value={orderExecutionStatus} key={orderExecutionStatus}>
                    {translate('iamdentityApp.OrderExecutionStatus.' + orderExecutionStatus)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.technicalOrderAction.executionStartTime')}
                id="technical-order-action-executionStartTime"
                name="executionStartTime"
                data-cy="executionStartTime"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.technicalOrderAction.executionEndTime')}
                id="technical-order-action-executionEndTime"
                name="executionEndTime"
                data-cy="executionEndTime"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.technicalOrderAction.executedCommand')}
                id="technical-order-action-executedCommand"
                name="executedCommand"
                data-cy="executedCommand"
                type="text"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.technicalOrderAction.executionResult')}
                id="technical-order-action-executionResult"
                name="executionResult"
                data-cy="executionResult"
                type="text"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.technicalOrderAction.priority')}
                id="technical-order-action-priority"
                name="priority"
                data-cy="priority"
                type="text"
              />
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.technicalOrderAction.connectorCommand" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="connectorCommand"
                  url={connectorCommandUrl}
                  queryProps={{
                    name: 'code',
                    type: 'string',
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.technicalOrderAction.iamSystem" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="iamSystem"
                  url={iamSystemUrl}
                  queryProps={{
                    name: 'name',
                    type: 'string',
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.technicalOrderAction.application" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="application"
                  url={applicationUrl}
                  queryProps={{
                    name: 'name',
                    type: 'string',
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.technicalOrderAction.orderedUserIdentity" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="orderedUserIdentity"
                  url={orderedUserIdentityUrl}
                  queryProps={{
                    name: 'id',
                    type: 'number',
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.technicalOrderAction.orderedRole" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="orderedRole"
                  url={orderedRoleUrl}
                  queryProps={{
                    name: 'id',
                    type: 'number',
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.technicalOrderAction.orderedGroup" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="orderedGroup"
                  url={orderedGroupUrl}
                  queryProps={{
                    name: 'id',
                    type: 'number',
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.technicalOrderAction.orderedSpecificPermission" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="orderedSpecificPermission"
                  url={orderedSpecificPermissionUrl}
                  queryProps={{
                    name: 'id',
                    type: 'number',
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.technicalOrderAction.order" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="order"
                  url={ordersUrl}
                  queryProps={{
                    name: 'id',
                    type: 'number',
                  }}
                />
              </FormGroup>
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.technicalOrderAction.rawConnectorLog')}
                id="technical-order-action-rawConnectorLog"
                name="rawConnectorLog"
                data-cy="rawConnectorLog"
                type="text"
              />
              <ValidatedField
                label={translate('iamdentityApp.technicalOrderAction.executionStage')}
                id="technical-order-action-executionStage"
                name="executionStage"
                data-cy="executionStage"
                type="select"
              >
                {toaExecutionStageValues.map(toaExecutionStage => (
                  <option value={toaExecutionStage} key={toaExecutionStage}>
                    {translate('iamdentityApp.ToaExecutionStage.' + toaExecutionStage)}
                  </option>
                ))}
              </ValidatedField>
              <div className="mt-1">
                <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={navigateBack} color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </div>
            </Form>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default TechnicalOrderActionUpdate;
