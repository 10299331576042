/* eslint-disable react/jsx-key */
import { faPen, faPlus, faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_GLOBAL_INSTANT_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { AuditRevType, IAudit, IAuditEntry } from 'app/shared/model/audit.model';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Translate, TextFormat, translate } from 'react-jhipster';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table, Spinner } from 'reactstrap';
import { getRevisionsByName } from './audit.reducer';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

interface ITimelineElementProps {
  change: IAuditEntry;
  toggleAuditModal2: (id: string) => void;
  entityDetailsName: string;
  entityLink: string;
}

const TimelineElement = ({ change, toggleAuditModal2, entityDetailsName, entityLink }: ITimelineElementProps) => {
  const isInsert = change.revType == AuditRevType.ADD;
  const revTypeName = change?.revType;
  const locale = useAppSelector(state => state.locale.currentLocale);

  return (
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      contentStyle={{ background: 'rgb(51, 179, 221)', color: '#fff' }}
      contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
      date={moment(change.modifiedAt).format(convertDateFormat(locale, 'instant'))}
      iconStyle={{ background: 'rgb(232, 50, 131)', color: '#fff' }}
      icon={
        <FontAwesomeIcon
          icon={
            revTypeName == AuditRevType.ADD
              ? faPlus
              : revTypeName == AuditRevType.MOD
              ? faPen
              : revTypeName == AuditRevType.DEL
              ? faRemove
              : null
          }
        />
      }
    >
      <h4 className="vertical-timeline-element-subtitle">
        {revTypeName == AuditRevType.DEL ? (
          <Button
            onClick={() => toggleAuditModal2(change?.id)}
            title={translate('iamdentityApp.audit.detailsClick')}
            color="light"
            size="sm"
            className="me-1"
          >
            <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: '5px' }} />
            <span className="d-none d-md-inline">
              <Translate contentKey="iamdentityApp.audit.details">Audit Details</Translate>
            </span>
          </Button>
        ) : (
          <div className="btn-group flex-btn-group-container me-1">
            <Button
              tag={Link}
              to={`/${entityLink}/${change?.id}`}
              title={translate('iamdentityApp.audit.entityDetailsClick')}
              color="info"
              size="sm"
            >
              <FontAwesomeIcon icon="eye" />
            </Button>
            <Button
              onClick={() => toggleAuditModal2(change?.id)}
              title={translate('iamdentityApp.audit.detailsClick')}
              color="light"
              size="sm"
            >
              <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: '5px' }} />
              <span className="d-none d-md-inline">
                <Translate contentKey="iamdentityApp.audit.details">Audit Details</Translate>
              </span>
            </Button>
          </div>
        )}
        {revTypeName == AuditRevType.ADD ? (
          <React.Fragment>
            <Translate
              contentKey="iamdentityApp.audit.globalEntitychanges.insert"
              interpolate={{ user: change?.modifiedBy, entityDetailsName: translate(`iamdentityApp.${entityDetailsName}.detail.title`) }}
            />
            &nbsp;
            <span
              onClick={() => toggleAuditModal2(change?.id)}
              title={translate('iamdentityApp.audit.detailsClick')}
              className="pointer text-decoration-underline"
            >
              <FontAwesomeIcon style={{ marginRight: '5px' }} icon={faLink} />
              {change?.id}
            </span>
            <span>{change?.fieldValue ? ' (' + change?.fieldValue + ')' : ''}</span>
          </React.Fragment>
        ) : revTypeName == AuditRevType.MOD ? (
          change?.fields.length == 1 ? (
            <React.Fragment>
              <Translate
                contentKey="iamdentityApp.audit.globalEntitychanges.modify"
                interpolate={{ user: change?.modifiedBy, entityDetailsName: translate(`iamdentityApp.${entityDetailsName}.detail.title`) }}
              />
              &nbsp;
              <span
                onClick={() => toggleAuditModal2(change?.id)}
                title={translate('iamdentityApp.audit.detailsClick')}
                className="pointer text-decoration-underline"
              >
                <FontAwesomeIcon style={{ marginRight: '5px' }} icon={faLink} />
                {change?.id}
              </span>
              <span>{change?.fieldValue ? ' (' + change?.fieldValue + ')' : ''}</span>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Translate
                contentKey="iamdentityApp.audit.globalEntitychanges.modifyMore"
                interpolate={{
                  user: change?.modifiedBy,
                  changesNumber: change?.fields?.length,
                  entityDetailsName: translate(`iamdentityApp.${entityDetailsName}.detail.title`),
                }}
              />
              &nbsp;
              <span
                onClick={() => toggleAuditModal2(change?.id)}
                title={translate('iamdentityApp.audit.detailsClick')}
                className="pointer text-decoration-underline"
              >
                <FontAwesomeIcon style={{ marginRight: '5px' }} icon={faLink} />
                {change?.id}
              </span>
              <span>{change?.fieldValue ? ' (' + change?.fieldValue + ')' : ''}</span>
            </React.Fragment>
          )
        ) : revTypeName == AuditRevType.DEL ? (
          <React.Fragment>
            <Translate
              contentKey="iamdentityApp.audit.globalEntitychanges.delete"
              interpolate={{ user: change?.modifiedBy, entityDetailsName: translate(`iamdentityApp.${entityDetailsName}.detail.title`) }}
            />
            &nbsp;
            <span
              onClick={() => toggleAuditModal2(change?.id)}
              title={translate('iamdentityApp.audit.detailsClick')}
              className="pointer text-decoration-underline"
            >
              <FontAwesomeIcon style={{ marginRight: '5px' }} icon={faLink} />
              {change?.id}
            </span>
            <span>{change?.fieldValue ? ' (' + change?.fieldValue + ')' : ''}</span>
          </React.Fragment>
        ) : (
          ''
        )}
      </h4>
      {revTypeName != AuditRevType.DEL && (
        <div>
          <Table>
            <thead>
              <tr>
                <th>{translate('iamdentityApp.audit.modal.field')}</th>
                {revTypeName == AuditRevType.MOD && <th>{translate('iamdentityApp.audit.modal.previousValue')}</th>}
                <th>{translate('iamdentityApp.audit.modal.currentValue')}</th>
              </tr>
            </thead>
            <tbody>
              {change?.fields?.map(field => (
                <tr>
                  <td>{field.name}</td>
                  {!isInsert && (
                    <td className="table-danger">
                      {field.previousValue && field.type === 'DATE' ? (
                        <TextFormat type="date" value={field.previousValue} format={convertDateFormat(locale, 'date')} />
                      ) : field.previousValue && field.type === 'DATETIME' ? (
                        <TextFormat type="date" value={field.previousValue} format={convertDateFormat(locale, 'instant')} />
                      ) : field.type === 'BOOLEAN' ? (
                        field.previousValue ? (
                          translate('global.yes')
                        ) : (
                          translate('global.no')
                        )
                      ) : (
                        field.previousValue
                      )}
                    </td>
                  )}
                  <td className="table-success">
                    {field.currentValue && field.type === 'DATE' ? (
                      <TextFormat type="date" value={field.currentValue} format={convertDateFormat(locale, 'date')} />
                    ) : field.currentValue && field.type === 'DATETIME' ? (
                      <TextFormat type="date" value={field.currentValue} format={convertDateFormat(locale, 'instant')} />
                    ) : field.type === 'BOOLEAN' ? (
                      field.currentValue ? (
                        translate('global.yes')
                      ) : (
                        translate('global.no')
                      )
                    ) : (
                      field.currentValue
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </VerticalTimelineElement>
  );
};

interface IModalProps {
  isOpen: boolean;
  toggle: () => void;
  entityName: string;
  toggleAuditModal2: (id: string) => void;
  entityFieldName: string;
  entityDetailsName: string;
  entityLink: string;
}

const AuditEntityModal = ({
  isOpen,
  toggle,
  entityName,
  toggleAuditModal2,
  entityFieldName,
  entityDetailsName,
  entityLink,
}: IModalProps) => {
  const dispatch = useAppDispatch();

  const [entityChanges, setEntityChanges] = useState<IAuditEntry[]>([]);

  const audit: IAudit = useAppSelector(state => state.audit.entities);
  const loading = useAppSelector(state => state.audit.loading);
  const updating = useAppSelector(state => state.audit.updating);

  useEffect(() => {
    if (isOpen) {
      dispatch(getRevisionsByName({ entityName, entityFieldName }));
    }
  }, [isOpen, entityName, entityFieldName]);

  useEffect(() => {
    if (audit) {
      const changes = [...(audit.entityChanges ?? []), ...(audit.customAttributesChanges ?? [])];
      setEntityChanges(changes.sort((a, b) => (moment(a.modifiedAt).isAfter(moment(b.modifiedAt)) ? -1 : 1)));
    }
  }, [audit]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="xl" scrollable={true}>
      <ModalHeader toggle={toggle}>
        <Translate contentKey="iamdentityApp.audit.modal.title" />
      </ModalHeader>
      <ModalBody>
        {loading || updating ? (
          <div className="entity-spinner-container">
            <Spinner color="primary">Loading...</Spinner>
          </div>
        ) : entityChanges?.length ? (
          <VerticalTimeline layout="1-column-left">
            {entityChanges?.map((change, index) => (
              <TimelineElement
                key={`change-${index}`}
                change={change}
                toggleAuditModal2={toggleAuditModal2}
                entityDetailsName={entityDetailsName}
                entityLink={entityLink}
              />
            ))}
          </VerticalTimeline>
        ) : (
          <em>
            <Translate contentKey="iamdentityApp.audit.modal.empty" />
          </em>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          {translate('entity.action.cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AuditEntityModal;
