import React, { useContext } from 'react';
import { translate } from 'react-jhipster';
import { Badge } from 'reactstrap';
import { Condition } from '../classes';
import { ExpressioNBuilderContext, renderNode } from '../expression-builder';
import '../style.scss';
import { DropdownClickProps } from '../types';
import { newNodeByValueAndType } from '../utils';
import NodeComponent from './node.component';

interface ConditionComponentProps {
  node: Condition;
}

const ConditionComponent = ({ node }: ConditionComponentProps) => {
  const { tree, setTree } = useContext(ExpressioNBuilderContext);

  const onChangeClick = ({ value, type }: DropdownClickProps) => {
    const newNode = newNodeByValueAndType(value, type, null, null, node.expressionEntity);
    newNode.acceptedValueTypes = node.acceptedValueTypes;
    newNode.expressionEntity = node.expressionEntity;
    newNode._type = node._type;
    tree.changeNode(node, newNode);
    setTree(tree);
  };

  const restrictedChildValueType = node.acceptedValueTypes && node.acceptedValueTypes.length == 1 ? node.acceptedValueTypes[0] : null;

  if (node.nodesNo == 1) {
    const arg1 = node.nodes[0];

    return (
      <div key={node.id} className="condition-row">
        <div className="condition-arg">{renderNode(arg1)}</div>
        <div className="condition-name">
          <NodeComponent onChange={onChangeClick} items={node.getAlternatives()} value={node.name} color="orange">
            {translate(`iamdentityApp.expressionBuilder.${node.name}`)}{' '}
            {restrictedChildValueType ? <Badge color="success">{restrictedChildValueType}</Badge> : ''}
          </NodeComponent>
        </div>
      </div>
    );
  } else if (node.nodesNo == 2) {
    const arg1 = node.nodes[0];
    const arg2 = node.nodes[1];

    return (
      <div key={node.id} className="condition-row">
        <div className="condition-arg">{renderNode(arg1)}</div>
        <div className="condition-name">
          <NodeComponent onChange={onChangeClick} items={node.getAlternatives()} value={node.name} color="orange">
            {translate(`iamdentityApp.expressionBuilder.${node.name}`)}{' '}
            {restrictedChildValueType ? <Badge color="success">{restrictedChildValueType}</Badge> : ''}
          </NodeComponent>
        </div>
        <div className="condition-arg">{renderNode(arg2)}</div>
      </div>
    );
  }

  return (
    <div>
      <NodeComponent value={node.name} />
      <div className="children-div">{node.nodes.map(child => renderNode(child))}</div>
    </div>
  );
};

export default ConditionComponent;
