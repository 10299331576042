import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate, translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import { AuditEntityName } from 'app/shared/model/audit.model';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import AuditModal from '../audit/audit-modal';
import ProfileUsers from './details-components/profile-users/profile-users';
import { getEntity } from './profile-definition.reducer';
import { convertDateFormat } from 'app/shared/util/date-utils';
import navigateBack from 'app/shared/components/handlers/buttons/back';

export const ProfileDefinitionDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [auditModal, setAuditModal] = useState<boolean>(false);
  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.PROFILE_DEFINITION));
  }, []);

  const profileDefinitionEntity = useAppSelector(state => state.profileDefinition.entity);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const locale = useAppSelector(state => state.locale.currentLocale);
  const checkIfExpired = () => {
    if (profileDefinitionEntity.endDate == null) return false;
    return new Date() > new Date(profileDefinitionEntity.endDate);
  };

  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };
  return (
    <Row>
      <Col md="3">
        <Card className="jh-card card-grey p-2">
          <h2 data-cy="profileDefinitionDetailsHeading">
            <Translate contentKey="iamdentityApp.profileDefinition.detail.title">ProfileDefinition</Translate>
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="id">
                <Translate contentKey="global.field.id">ID</Translate>
              </span>
            </dt>
            <dd>{profileDefinitionEntity.id}</dd>
            <dt>
              <span id="name">
                <Translate contentKey="iamdentityApp.profileDefinition.name">Name</Translate>
              </span>
            </dt>
            <dd>{profileDefinitionEntity.name}</dd>
            <dt>
              <span id="code">
                <Translate contentKey="iamdentityApp.profileDefinition.code">Code</Translate>
              </span>
            </dt>
            <dd>{profileDefinitionEntity.code}</dd>
            <dt>
              <span id="description">
                <Translate contentKey="iamdentityApp.profileDefinition.description">Description</Translate>
              </span>
            </dt>
            <dd>{profileDefinitionEntity.description}</dd>
            <dt>
              <span id="startDate">
                <Translate contentKey="iamdentityApp.profileDefinition.startDate">Start Date</Translate>
              </span>
            </dt>
            <dd>
              {profileDefinitionEntity.startDate ? (
                <TextFormat value={profileDefinitionEntity.startDate} type="date" format={convertDateFormat(locale, 'date')} />
              ) : null}
            </dd>
            <dt>
              <span id="endDate">
                <Translate contentKey="iamdentityApp.profileDefinition.endDate">End Date</Translate>
              </span>
            </dt>
            <dd>
              {profileDefinitionEntity.endDate ? (
                <TextFormat value={profileDefinitionEntity.endDate} type="date" format={convertDateFormat(locale, 'date')} />
              ) : null}
            </dd>
            <dt>
              <span id="uniqueKey">
                <Translate contentKey="iamdentityApp.profileDefinition.uniqueKey">Unique Key</Translate>
              </span>
            </dt>
            <dd>{profileDefinitionEntity.uniqueKey}</dd>
            <dt>
              <span id="partial">
                <Translate contentKey="iamdentityApp.profileDefinition.partial">Partial</Translate>
              </span>
            </dt>
            <dd>{profileDefinitionEntity.partial ? translate('global.yes') : translate('global.no')}</dd>
            <dt>
              <Translate contentKey="global.field.createdBy">Created By</Translate>
            </dt>
            <dd>{profileDefinitionEntity.createdBy ? profileDefinitionEntity.createdBy : ''}</dd>
            <dt>
              <Translate contentKey="global.field.createdDate">Created Date</Translate>
            </dt>
            <dd>
              {profileDefinitionEntity.createdDate ? (
                <TextFormat value={profileDefinitionEntity.createdDate} type="date" format={convertDateFormat(locale, 'date')} />
              ) : null}
            </dd>
            <dt>
              <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
            </dt>
            <dd>{profileDefinitionEntity.lastModifiedBy ? profileDefinitionEntity.lastModifiedBy : ''}</dd>
            <dt>
              <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
            </dt>
            <dd>
              {profileDefinitionEntity.lastModifiedDate ? (
                <TextFormat value={profileDefinitionEntity.lastModifiedDate} type="date" format={convertDateFormat(locale, 'date')} />
              ) : null}
            </dd>
          </dl>
          <Row>
            <Col className="noPad">
              <Button
                block
                id="cancel-save"
                data-cy="entityDetailsBackButton"
                onClick={navigateBack}
                color="info"
                style={{ borderRadius: 0 }}
              >
                <FontAwesomeIcon icon="arrow-left" />{' '}
              </Button>
            </Col>
            <Col className="noPad">
              <Button
                block
                tag={Link}
                to={`/profile-definition/${profileDefinitionEntity.id}/edit`}
                replace
                color="warning"
                style={{ borderRadius: 0 }}
              >
                <FontAwesomeIcon icon="pencil-alt" />{' '}
              </Button>
            </Col>
            <Col className="noPad">
              <Button block onClick={toggleAuditModal} color="light" style={{ borderRadius: 0 }}>
                <FontAwesomeIcon icon={faClockRotateLeft} />{' '}
              </Button>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col md="9">
        <div className="content-container ml-1">
          <ProfileUsers profileDefinitionId={parseInt(id, 10)} isProfileExpired={checkIfExpired()} />
        </div>
      </Col>
      <Col md="12">
        <AuditModal isOpen={auditModal} toggle={toggleAuditModal} entityId={id} entityName={AuditEntityName.PROFILE_DEFINITION} />
      </Col>
    </Row>
  );
};

export default ProfileDefinitionDetail;
