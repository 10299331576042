import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { getSortState, JhiItemCount, JhiPagination, TextFormat, translate, Translate } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, Table, UncontrolledTooltip } from 'reactstrap';

import { APP_LOCAL_DATE_FORMAT, APP_TABLE_TEXT_MAX_CHARS, INPUT_ENUM_SEPARATOR } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';

import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import Filter from 'app/shared/FilterComponent';
import { CustomAttributeType } from 'app/shared/model/enumerations/custom-attribute-type.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { getQueryParamsIfExists } from 'app/shared/util/filter-utils';
import { reset, exportEntities, getEntities, searchEntities } from './custom-attribute-definition.reducer';
import PaginatedDropdown from 'app/shared/components/pagination-dropdown';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { handleSortIconsTable } from 'app/shared/util/table-icons';
import CustomText from 'app/shared/components/customText/customText';
import { AuditEntityName } from 'app/shared/model/audit.model';
import AuditEntityModal from '../audit/audit-entity-modal';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import AuditModal from '../audit/audit-modal';

export const CustomAttributeDefinition = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const [auditModal, setAuditModal] = useState<boolean>(false);
  const [auditModal2, setAuditModal2] = useState<boolean>(false);
  const [entityId, setEntityId] = useState<string>('');

  const [search, setSearch] = useState('');

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const handlePageSizeChange = size => {
    paginationState.activePage = 1;
    paginationState.itemsPerPage = size;
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query,
      })
    );
  };
  const [filterShown, setFilterShown] = useState<boolean>(true);
  const [query, setQuery] = useState<string>(getQueryParamsIfExists(location.search));

  const customAttributeDefinitionList = useAppSelector(state => state.customAttributeDefinition.entities);
  const loading = useAppSelector(state => state.customAttributeDefinition.loading);
  const totalItems = useAppSelector(state => state.customAttributeDefinition.totalItems);
  const locale = useAppSelector(state => state.locale.currentLocale);

  const toggleFilter = () => {
    if (filterShown) setQuery('');
    setFilterShown(!filterShown);
  };

  const getAllEntities = () => {
    if (search) {
      dispatch(
        searchEntities({
          query: search,
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        })
      );
    } else {
      dispatch(
        getEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query,
        })
      );
    }
  };

  const startSearching = e => {
    if (search) {
      setPaginationState({
        ...paginationState,
        activePage: 1,
      });
      dispatch(
        searchEntities({
          query: search,
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        })
      );
    }
    e.preventDefault();
  };

  const clear = () => {
    setSearch('');
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    dispatch(getEntities({}));
  };

  const handleSearch = event => setSearch(event.target.value);

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}&query=${query}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, search, query]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
    dispatch(reset());
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const exportTable = () => {
    dispatch(
      exportEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query,
      })
    );
  };

  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };

  const toggleAuditModal2 = (id?: string) => {
    if (id) {
      setEntityId(id);
    }
    setAuditModal(false);
    setAuditModal2(!auditModal2);
  };

  return (
    <Card className="jh-card h-100">
      <h2 id="custom-attribute-definition-heading" data-cy="CustomAttributeDefinitionHeading">
        <Translate contentKey="iamdentityApp.customAttributeDefinition.home.title">Custom Attribute Definitions</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} className={'me-1'} />
            <Translate contentKey="iamdentityApp.customAttributeDefinition.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Button className="me-2" color="light" onClick={exportTable} disabled={loading}>
            <FontAwesomeIcon icon="file-excel" spin={loading} />
            <Translate contentKey="entity.action.export">Export</Translate>
          </Button>
          <Button onClick={toggleAuditModal} color="light" className="me-2">
            <FontAwesomeIcon icon={faClockRotateLeft} />
            <Translate contentKey="entity.action.history">History</Translate>
          </Button>
          <Link
            to="/custom-attribute-definition/new"
            className="btn btn-success jh-create-entity"
            id="jh-create-entity"
            data-cy="entityCreateButton"
          >
            <FontAwesomeIcon icon="plus" />
            <Translate contentKey="iamdentityApp.customAttributeDefinition.home.createLabel">
              Create new Custom Attribute Definition
            </Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive table-scroll-custom h-100">
        <Table responsive>
          <thead className="table-scroll-custom_thead">
            {filterShown && (
              <Filter
                getEntities={setQuery}
                columns={[
                  {
                    name: 'id',
                    type: 'string',
                    placeholder: translate(`iamdentityApp.customAttributeDefinition.id`),
                  },
                  {
                    name: 'displayName',
                    type: 'string',
                    placeholder: translate(`iamdentityApp.customAttributeDefinition.displayName`),
                  },
                  {
                    name: 'description',
                    type: 'string',
                    placeholder: translate(`iamdentityApp.customAttributeDefinition.description`),
                  },
                  {
                    name: 'customAttributeType',
                    type: 'select',
                    values: [
                      { value: 'placeholder', label: '' },
                      ...Object.values(CustomAttributeType).map(value => ({
                        value,
                        label: translate(`iamdentityApp.CustomAttributeType.${value}`),
                      })),
                    ],
                  },
                  {
                    name: 'selectableOptions',
                    type: 'string',
                    placeholder: translate(`iamdentityApp.customAttributeDefinition.selectableOptions`),
                  },
                  {
                    name: 'displayOrder',
                    type: 'number',
                  },
                  {
                    name: 'startDate',
                    type: 'date',
                  },
                  {
                    name: 'endDate',
                    type: 'date',
                  },
                  {
                    name: 'objectTypes',
                    type: 'select',
                    values: [
                      { value: 'placeholder', label: '' },
                      ...Object.values(CustomAttributeApplicableObjectType)
                        .sort()
                        .map(value => ({
                          value,
                          label: translate(`iamdentityApp.CustomAttributeApplicableObjectType.${value}`),
                        })),
                    ],
                  },
                ]}
              />
            )}
            <tr>
              <th className="hand id-size" onClick={sort('id')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.customAttributeDefinition.id">ID</Translate>
                  </CustomText>
                  {handleSortIconsTable('id')}
                </div>
              </th>
              <th className="hand" onClick={sort('displayName')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.customAttributeDefinition.displayName">Display Name</Translate>
                  </CustomText>
                  {handleSortIconsTable('displayName')}
                </div>
              </th>
              <th className="hand" onClick={sort('description')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.customAttributeDefinition.description">Description</Translate>
                  </CustomText>
                  {handleSortIconsTable('description')}
                </div>
              </th>
              <th className="hand" onClick={sort('customAttributeType')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.customAttributeDefinition.customAttributeType">Custom Attribute Type</Translate>
                  </CustomText>
                  {handleSortIconsTable('customAttributeType')}
                </div>
              </th>
              <th className="hand" onClick={sort('selectableOptions')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.customAttributeDefinition.selectableOptions">Selectable Options</Translate>
                  </CustomText>
                  {handleSortIconsTable('selectableOptions')}
                </div>
              </th>
              <th className="hand" onClick={sort('displayOrder')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.customAttributeDefinition.displayOrder">Display Order</Translate>
                  </CustomText>
                  {handleSortIconsTable('displayOrder')}
                </div>
              </th>
              <th className="hand" onClick={sort('startDate')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.customAttributeDefinition.startDate">Start Date</Translate>
                  </CustomText>
                  {handleSortIconsTable('startDate')}
                </div>
              </th>
              <th className="hand" onClick={sort('endDate')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.customAttributeDefinition.endDate">End Date</Translate>
                  </CustomText>
                  {handleSortIconsTable('endDate')}
                </div>
              </th>
              <th className={'hand'}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.customAttributeDefinition.objectTypes">Object Types</Translate>
                  </CustomText>
                </div>
              </th>
              <th className="hand">
                {/*<span>*/}
                {/*  <Button size="sm" id="toggle-filter" color={filterShown ? 'secondary' : 'default'} onClick={toggleFilter}>*/}
                {/*    <FontAwesomeIcon icon="magnifying-glass" />*/}
                {/*  </Button>*/}
                {/*</span>*/}
                {/*<UncontrolledTooltip placement="top" target="toggle-filter">*/}
                {/*  <Translate contentKey={filterShown ? 'global.hideFilter' : 'global.showFilter'}>Show/Hide Filter</Translate>*/}
                {/*</UncontrolledTooltip>*/}
              </th>
            </tr>
          </thead>
          <tbody>
            {customAttributeDefinitionList.map((customAttributeDefinition, i) => (
              <tr key={`entity-${i}`} data-cy="entityTable">
                <td>
                  <Link to={`/custom-attribute-definition/${customAttributeDefinition.id}`} color="link">
                    {customAttributeDefinition.id}
                  </Link>
                </td>
                <td>
                  <CustomText>{customAttributeDefinition.displayName}</CustomText>
                </td>
                <td>
                  <CustomText>{customAttributeDefinition.description}</CustomText>
                </td>
                <td>
                  <CustomText>{translate(`iamdentityApp.CustomAttributeType.${customAttributeDefinition.customAttributeType}`)}</CustomText>
                </td>
                <td>
                  <CustomText>{customAttributeDefinition?.selectableOptions?.split(INPUT_ENUM_SEPARATOR).join(', ')}</CustomText>
                </td>
                <td>
                  <CustomText>{customAttributeDefinition.displayOrder}</CustomText>
                </td>
                <td>
                  {customAttributeDefinition.startDate ? (
                    <TextFormat type="date" value={customAttributeDefinition.startDate} format={convertDateFormat(locale, 'date')} />
                  ) : null}
                </td>
                <td>
                  {customAttributeDefinition.endDate ? (
                    <TextFormat type="date" value={customAttributeDefinition.endDate} format={convertDateFormat(locale, 'date')} />
                  ) : null}
                </td>
                <td>
                  {customAttributeDefinition.objectTypes?.map((obType, index) => (
                    <React.Fragment key={obType.id}>
                      <Translate contentKey={`iamdentityApp.CustomAttributeApplicableObjectType.${obType.objectType}`}>
                        Object Types
                      </Translate>
                      {index != customAttributeDefinition.objectTypes?.length - 1 ? ', ' : ''}
                    </React.Fragment>
                  ))}
                </td>
                <td className="text-end">
                  <div className="btn-group flex-btn-group-container">
                    <Button
                      tag={Link}
                      to={`/custom-attribute-definition/${customAttributeDefinition.id}`}
                      color="info"
                      size="sm"
                      data-cy="entityDetailsButton"
                    >
                      <FontAwesomeIcon icon="eye" />
                      {/*<span className="d-none d-md-inline">*/}
                      {/*    <Translate contentKey="entity.action.view">View</Translate>*/}
                      {/*  </span>*/}
                    </Button>
                    <Button
                      tag={Link}
                      to={`/custom-attribute-definition/${customAttributeDefinition.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                      color="warning"
                      size="sm"
                      data-cy="entityEditButton"
                    >
                      <FontAwesomeIcon icon="pencil-alt" />
                      {/*<span className="d-none d-md-inline">*/}
                      {/*    <Translate contentKey="entity.action.edit">Edit</Translate>*/}
                      {/*  </span>*/}
                    </Button>
                    <Button
                      tag={Link}
                      to={`/custom-attribute-definition/${customAttributeDefinition.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                      color="primary"
                      size="sm"
                      data-cy="entityDeleteButton"
                    >
                      <FontAwesomeIcon icon="trash" />
                      {/*<span className="d-none d-md-inline">*/}
                      {/*    <Translate contentKey="entity.action.delete">Delete</Translate>*/}
                      {/*  </span>*/}
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {(!customAttributeDefinitionList || customAttributeDefinitionList.length < 1) && !loading && (
          <div className="alert alert-warning mt-1">
            <Translate contentKey="iamdentityApp.customAttributeDefinition.home.notFound">No Custom Attribute Definitions found</Translate>
          </div>
        )}
      </div>
      {totalItems ? (
        <div className={customAttributeDefinitionList && customAttributeDefinitionList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex mt-2">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
            <PaginatedDropdown itemsPerPage={paginationState.itemsPerPage} handlePageSizeChange={handlePageSizeChange}></PaginatedDropdown>
          </div>
        </div>
      ) : (
        ''
      )}
      <AuditEntityModal
        isOpen={auditModal}
        toggle={toggleAuditModal}
        entityName={AuditEntityName.CUSTOM_ATTRIBUTE_DEFINITION}
        toggleAuditModal2={toggleAuditModal2}
        entityFieldName={'displayName'}
        entityDetailsName={'customAttributeDefinition'}
        entityLink={'custom-attribute-definition'}
      />
      <AuditModal
        isOpen={auditModal2}
        toggle={() => toggleAuditModal2()}
        entityId={entityId}
        entityName={AuditEntityName.CUSTOM_ATTRIBUTE_DEFINITION}
      />
    </Card>
  );
};

export default CustomAttributeDefinition;
