import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useEffect, useRef, useState } from 'react';
import { TextFormat, Translate, translate } from 'react-jhipster';
import CompactExpressionBuilder from 'app/shared/components/expression-builder/compact-expression-builder';
import { Link, Route, useNavigate, useParams } from 'react-router-dom';
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Card,
  Row,
  Col,
  Table,
  Input,
  InputGroup,
  UncontrolledAccordion,
} from 'reactstrap';

import { getEntities as getUserIdentities, reset } from 'app/entities/user-identity/user-identity.reducer';
import { getEntitiesByEmployeeId as getUserGroupsByEmployeeId, reset as resetUserGroup } from 'app/entities/user-group/user-group.reducer';
import {
  getEntitiesByEmployeeId as getUserIdentityRolesByEmployeeId,
  reset as resetUserIdentityRoles,
} from 'app/entities/user-identity-role/user-identity-role.reducer';
import {
  getEntitiesByEmployeeId as getSpecificPermissionsByEmployeeId,
  reset as resetSpecificPermissions,
} from 'app/entities/specific-permission/specific-permission.reducer';
import {
  getEntitiesByEmployeeId as getApplicationsByEmployeeId,
  reset as resetApplication,
} from 'app/entities/application/application.reducer';
import { APP_LOCAL_DATE_FORMAT, APP_DATE_FORMAT } from 'app/config/constants';

import { IApplication } from 'app/shared/model/application.model';
import { IUserIdentity, IUserIdentityAccessRights } from 'app/shared/model/user-identity.model';
import { IUserGroup } from 'app/shared/model/user-group.model';
import { IUserIdentityRole } from 'app/shared/model/user-identity-role.model';
import { ISpecificPermission } from 'app/shared/model/specific-permission.model';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import InteractionDrawer from 'app/entities/orders/details-components/requested-users/drawer/interaction-drawer';
import EmployeeRemoveUserDialog from '../employee-remove-user-dialog';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import FormUser, { ModifyUserData } from '../modify-user-data';
import { convertDateFormat } from 'app/shared/util/date-utils';

interface IAccessRightsProps {
  employeeId: number;
}

interface IEmployeeApplication {
  application: IApplication;
  userIdentities: IUserIdentityAccessRights[];
}

const AccessRights = ({ employeeId }: IAccessRightsProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // select component data lists
  const userIdentitiesList: IUserIdentity[] = useAppSelector(state => state.userIdentity.entities);
  const userGroupList: IUserGroup[] = useAppSelector(state => state.userGroup.entities);
  const userIdentityRoleList: IUserIdentityRole[] = useAppSelector(state => state.userIdentityRole.entities);
  const specificPermissionsList: ISpecificPermission[] = useAppSelector(state => state.specificPermission.entities);
  const applicationList: IApplication[] = useAppSelector(state => state.application.entities);
  const locale = useAppSelector(state => state.locale.currentLocale);
  const loading = useAppSelector(state => state.application.loading || state.userIdentity.loading);
  const [applications, setApplications] = useState<IEmployeeApplication[]>([]);

  //dispatch data
  useEffect(() => {
    dispatch(getUserIdentities({ query: 'employeeId.equals=' + employeeId.toString() + `&status.in=ACTIVE,SUSPENDED&page=0&size=200` }));
    dispatch(getApplicationsByEmployeeId(employeeId));
    dispatch(
      getUserGroupsByEmployeeId({ query: 'employeeId.equals=' + employeeId.toString() + `&status.in=ACTIVE,SUSPENDED&page=0&size=200` })
    );
    dispatch(
      getUserIdentityRolesByEmployeeId({
        query: 'employeeId.equals=' + employeeId.toString() + `&status.in=ACTIVE,SUSPENDED&page=0&size=200`,
      })
    );
    dispatch(
      getSpecificPermissionsByEmployeeId({
        query: 'employeeId.equals=' + employeeId.toString() + `&status.in=ACTIVE,SUSPENDED&page=0&size=200`,
      })
    );
  }, [employeeId]);

  useEffect(() => {
    let newApplications: IEmployeeApplication[] = [];
    if (!loading && applicationList.length && userIdentitiesList.length) {
      newApplications = applicationList.map(app => ({
        application: app,
        userIdentities: userIdentitiesList
          .filter(ui => ui?.application?.id === app?.id)
          .map(ui => ({
            ...ui,
            userGroups: userGroupList?.filter(ug => ug?.userIdentity?.id === ui?.id),
            userIdentityRoles: userIdentityRoleList?.filter(uir => uir?.userIdentity?.id === ui?.id),
            specificPermissions: specificPermissionsList?.filter(sp => sp?.userIdentity?.id === ui?.id),
          })),
      }));

      setApplications(newApplications);
    } else {
      setApplications([]);
    }
  }, [loading, applicationList, userIdentitiesList, userGroupList, userIdentityRoleList, specificPermissionsList]);

  return (
    <React.Fragment>
      <UncontrolledAccordion open="" defaultOpen={['0']}>
        {applications.length ? (
          applications.map((app, index) => {
            const application = app.application;
            const accessRightsUserIdentities = app.userIdentities;
            return (
              <AccordionItem key={`accordion-item-${application.id}`}>
                <AccordionHeader className="accordion-application-header" targetId={`${application.id}`}>
                  <div className="flex-div full-width justify-space-between mr-1">
                    <div>
                      <Translate contentKey="iamdentityApp.application.detail.title">Application</Translate>: {application.name}
                    </div>
                  </div>
                </AccordionHeader>
                <AccordionBody accordionId={`${application.id}`}>
                  {accessRightsUserIdentities.length ? (
                    accessRightsUserIdentities.map(userIdentity => {
                      const userGroups = userIdentity.userGroups;
                      const userIdentityRoles = userIdentity.userIdentityRoles;
                      const specificPermissions = userIdentity.specificPermissions;
                      return (
                        <div key={userIdentity.id} className="details-grid-container details-grid-row">
                          <div className="summary-container">
                            <Card>
                              <Row>
                                <Col className="dark-text">
                                  <dl className="jh-entity-details">
                                    <dt>
                                      <Translate contentKey="iamdentityApp.userIdentity.id">Id</Translate>:
                                    </dt>
                                    <dd>
                                      {userIdentity?.id ? <Link to={`/user-identity/${userIdentity.id}`}>{userIdentity.id}</Link> : null}
                                    </dd>
                                    <dt>
                                      <Translate contentKey="iamdentityApp.userIdentity.application">Application</Translate>
                                    </dt>
                                    <dd>
                                      {userIdentity?.application?.id && userIdentity.application.name ? (
                                        <Link to={`/application/${userIdentity.application.id}`}>{userIdentity.application.name}</Link>
                                      ) : (
                                        ''
                                      )}
                                    </dd>
                                    <dt>
                                      <Translate contentKey="iamdentityApp.userIdentity.userName">User Name</Translate>
                                    </dt>
                                    <dd>{userIdentity?.userName ? userIdentity.userName : ''}</dd>
                                    <dt>
                                      <Translate contentKey="iamdentityApp.userIdentity.status">Status</Translate>
                                    </dt>
                                    <dd>{userIdentity?.status ? translate(`iamdentityApp.UserStatus.${userIdentity.status}`) : ''}</dd>
                                    <dt>
                                      <Translate contentKey="iamdentityApp.userIdentity.grantedBy">Granted by Order Type</Translate>
                                    </dt>
                                    <dd>
                                      {userIdentity?.grantedBy
                                        ? translate(`iamdentityApp.PermissionGrantLevel.${userIdentity.grantedBy}`)
                                        : ''}
                                    </dd>
                                    <dt>
                                      <Translate contentKey="iamdentityApp.userIdentity.startDate">Start Date</Translate>
                                    </dt>
                                    <dd>
                                      {userIdentity?.startDate ? (
                                        <TextFormat type="date" value={userIdentity.startDate} format={convertDateFormat(locale, 'date')} />
                                      ) : null}
                                    </dd>
                                    <dt>
                                      <Translate contentKey="iamdentityApp.userIdentity.endDate">End Date</Translate>
                                    </dt>
                                    <dd>
                                      {userIdentity?.endDate ? (
                                        <TextFormat type="date" value={userIdentity.endDate} format={convertDateFormat(locale, 'date')} />
                                      ) : null}
                                    </dd>
                                    <dt>
                                      {userIdentity?.explicitlyRemoved && (
                                        <Translate contentKey="iamdentityApp.userIdentity.explicitlyRemoved">Explicitly Removed</Translate>
                                      )}
                                    </dt>
                                    <dt>
                                      <Translate contentKey="iamdentityApp.userIdentity.lastModifiedByOrder">
                                        Last Modified By Order
                                      </Translate>
                                    </dt>
                                    <dd>
                                      {userIdentity?.lastModifiedByOrder?.id ? (
                                        <Link to={`/orders/${userIdentity.lastModifiedByOrder.id}`}>
                                          {userIdentity.lastModifiedByOrder.id}
                                        </Link>
                                      ) : (
                                        ''
                                      )}
                                    </dd>
                                  </dl>
                                </Col>
                              </Row>
                              <Button
                                tag={Link}
                                to={`removeUser/${userIdentity.id}`}
                                color="warning"
                                size="sm"
                                data-cy="entityRemoveButton"
                              >
                                <FontAwesomeIcon icon={faUndo} />
                                <span className="d-none d-md-inline">
                                  <Translate contentKey="entity.action.removeUser">Remove User</Translate>
                                </span>
                              </Button>
                              <Button
                                tag={Link}
                                to={`modifyUserData/${userIdentity.id}`}
                                color="secondary"
                                size="sm"
                                data-cy="entityModifyUserDataButton"
                              >
                                <FontAwesomeIcon icon="pencil-alt" />
                                <span className="d-none d-md-inline">
                                  <Translate contentKey="entity.action.modifyUserData">Modify User Data</Translate>
                                </span>
                              </Button>
                            </Card>
                          </div>
                          <div className="content-container ml-1">
                            <UncontrolledAccordion open="" defaultOpen={['user-groups']}>
                              <AccordionItem>
                                <AccordionHeader className="accordion-application-header" targetId="user-groups">
                                  <div className="flex-div full-width justify-space-between mr-1">
                                    <div>
                                      <Translate contentKey="iamdentityApp.userGroup.home.title">User Groups</Translate>
                                    </div>
                                  </div>
                                </AccordionHeader>
                                <AccordionBody accordionId="user-groups">
                                  <div className="table-responsive">
                                    {userGroups.length ? (
                                      <Table responsive>
                                        <thead>
                                          <tr>
                                            <th className="text-center">
                                              <Translate contentKey="iamdentityApp.userGroup.userIdentity">User Identity</Translate>
                                            </th>
                                            <th className="text-center">
                                              <Translate contentKey="iamdentityApp.userGroup.roleGroup">Role Group</Translate>
                                            </th>
                                            <th className="text-center">
                                              <Translate contentKey="iamdentityApp.userGroup.grantedBy">Granted By</Translate>
                                            </th>
                                            <th className="text-center">
                                              <Translate contentKey="iamdentityApp.userGroup.startDate">Start Date</Translate>
                                            </th>
                                            <th className="text-center">
                                              <Translate contentKey="iamdentityApp.userGroup.endDate">End Date</Translate>
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {userGroups.map((userGroup, i) => (
                                            <tr key={`group-${i}`}>
                                              <td>
                                                {userGroup.userIdentity ? (
                                                  <Link to={`/user-identity/${userGroup.userIdentity.id}`}>
                                                    {userGroup.userIdentity?.userName}
                                                  </Link>
                                                ) : (
                                                  ''
                                                )}
                                              </td>
                                              <td>
                                                {userGroup.roleGroup ? (
                                                  <Link to={`/role-group/${userGroup.roleGroup?.id}`}>{userGroup.roleGroup?.name}</Link>
                                                ) : (
                                                  ''
                                                )}
                                              </td>
                                              <td>{translate(`iamdentityApp.PermissionGrantLevel.${userGroup.grantedBy}`)}</td>
                                              <td>
                                                {userGroup.startDate ? (
                                                  <TextFormat
                                                    type="date"
                                                    value={userGroup.startDate}
                                                    format={convertDateFormat(locale, 'date')}
                                                  />
                                                ) : null}
                                              </td>
                                              <td>
                                                {userGroup.endDate ? (
                                                  <TextFormat
                                                    type="date"
                                                    value={userGroup.endDate}
                                                    format={convertDateFormat(locale, 'date')}
                                                  />
                                                ) : null}
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </Table>
                                    ) : (
                                      <div className="alert alert-warning">
                                        <Translate contentKey="iamdentityApp.userGroup.home.notFound">No User Groups found</Translate>
                                      </div>
                                    )}
                                  </div>
                                </AccordionBody>
                              </AccordionItem>

                              <AccordionItem>
                                <AccordionHeader className="accordion-application-header" targetId="user-identity-roles">
                                  <div className="flex-div full-width justify-space-between mr-1">
                                    <div>
                                      <Translate contentKey="iamdentityApp.userIdentityRole.home.title">User Identity Roles</Translate>
                                    </div>
                                  </div>
                                </AccordionHeader>
                                <AccordionBody accordionId="user-identity-roles">
                                  <div className="table-responsive">
                                    {userIdentityRoles.length ? (
                                      <Table responsive>
                                        <thead>
                                          <tr>
                                            <th>
                                              <Translate contentKey="iamdentityApp.userIdentityRole.userIdentity">User Identity</Translate>{' '}
                                            </th>
                                            <th>
                                              <Translate contentKey="iamdentityApp.userIdentityRole.roleDefinition">
                                                Role Definition
                                              </Translate>{' '}
                                            </th>
                                            <th className="text-center">
                                              <Translate contentKey="iamdentityApp.userIdentityRole.grantedBy">Granted By</Translate>
                                            </th>
                                            <th className="text-center">
                                              <Translate contentKey="iamdentityApp.userIdentityRole.startDate">Start Date</Translate>
                                            </th>
                                            <th className="text-center">
                                              <Translate contentKey="iamdentityApp.userIdentityRole.endDate">End Date</Translate>
                                            </th>
                                            <th className="text-center">
                                              <Translate contentKey="iamdentityApp.userIdentityRole.description">Description</Translate>
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {userIdentityRoles.map((userIdentityRole, i) => (
                                            <tr key={`role-${i}`}>
                                              <td>
                                                {userIdentityRole?.userIdentity ? (
                                                  <Link to={`/user-identity/${userIdentityRole.userIdentity?.id}`}>
                                                    {userIdentityRole.userIdentity?.userName}
                                                  </Link>
                                                ) : (
                                                  ''
                                                )}
                                              </td>
                                              <td>
                                                {userIdentityRole?.roleDefinition ? (
                                                  <Link to={`/role-definition/${userIdentityRole.roleDefinition?.id}`}>
                                                    {userIdentityRole.roleDefinition?.name}
                                                  </Link>
                                                ) : (
                                                  ''
                                                )}
                                              </td>
                                              <td>{translate(`iamdentityApp.PermissionGrantLevel.${userIdentityRole.grantedBy}`)}</td>
                                              <td>
                                                {userIdentityRole.startDate ? (
                                                  <TextFormat type="date" value={userIdentityRole.startDate} format={APP_DATE_FORMAT} />
                                                ) : null}
                                              </td>
                                              <td>
                                                {userIdentityRole.endDate ? (
                                                  <TextFormat type="date" value={userIdentityRole.endDate} format={APP_DATE_FORMAT} />
                                                ) : null}
                                              </td>
                                              <td>{userIdentityRole.description}</td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </Table>
                                    ) : (
                                      <div className="alert alert-warning">
                                        <Translate contentKey="iamdentityApp.userIdentityRole.home.notFound">
                                          No User Identity Role found
                                        </Translate>
                                      </div>
                                    )}
                                  </div>
                                </AccordionBody>
                              </AccordionItem>
                              <AccordionItem>
                                <AccordionHeader className="accordion-application-header" targetId="specific-permissions">
                                  <div className="flex-div full-width justify-space-between mr-1">
                                    <div>
                                      <Translate contentKey="iamdentityApp.specificPermission.home.title">Specific Permissions</Translate>
                                    </div>
                                  </div>
                                </AccordionHeader>
                                <AccordionBody accordionId="specific-permissions">
                                  <div className="table-responsive">
                                    {specificPermissions.length ? (
                                      <Table responsive>
                                        <thead>
                                          <tr>
                                            <th>
                                              <Translate contentKey="iamdentityApp.specificPermission.userIdentity">
                                                User Identity
                                              </Translate>
                                            </th>
                                            <th>
                                              <Translate contentKey="iamdentityApp.specificPermission.permissionDefinition">
                                                Permission Definition
                                              </Translate>
                                            </th>
                                            <th className="text-center">
                                              <Translate contentKey="iamdentityApp.specificPermission.grantedBy">Granted By</Translate>
                                            </th>
                                            <th className="text-center">
                                              <Translate contentKey="iamdentityApp.specificPermission.startDate">Start Date</Translate>
                                            </th>
                                            <th className="text-center">
                                              <Translate contentKey="iamdentityApp.specificPermission.endDate">End Date</Translate>
                                            </th>
                                            <th className="text-center">
                                              <Translate contentKey="iamdentityApp.specificPermission.description">Description</Translate>
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {specificPermissions.map((specificPermission, i) => (
                                            <tr key={`specific-permission-${i}`}>
                                              <td>
                                                {specificPermission.userIdentity ? (
                                                  <Link to={`/user-identity/${specificPermission.userIdentity.id}`}>
                                                    {specificPermission.userIdentity?.userName}
                                                  </Link>
                                                ) : (
                                                  ''
                                                )}
                                              </td>
                                              <td>
                                                {specificPermission.permissionDefinition ? (
                                                  <Link to={`/permission-definition/${specificPermission.permissionDefinition?.id}`}>
                                                    {specificPermission.permissionDefinition?.name}
                                                  </Link>
                                                ) : (
                                                  ''
                                                )}
                                              </td>
                                              <td>{translate(`iamdentityApp.PermissionGrantLevel.${specificPermission.grantedBy}`)}</td>
                                              <td>
                                                {specificPermission.startDate ? (
                                                  <TextFormat type="date" value={specificPermission.startDate} format={APP_DATE_FORMAT} />
                                                ) : null}
                                              </td>
                                              <td>
                                                {specificPermission.endDate ? (
                                                  <TextFormat type="date" value={specificPermission.endDate} format={APP_DATE_FORMAT} />
                                                ) : null}
                                              </td>
                                              <td>{specificPermission.description}</td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </Table>
                                    ) : (
                                      <div className="alert alert-warning">
                                        <Translate contentKey="iamdentityApp.specificPermission.home.notFound">
                                          No Specific Permissions found
                                        </Translate>
                                      </div>
                                    )}
                                  </div>
                                </AccordionBody>
                              </AccordionItem>
                            </UncontrolledAccordion>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="alert alert-warning">
                      <Translate contentKey="iamdentityApp.userIdentity.home.notFound">Not found</Translate>
                    </div>
                  )}
                </AccordionBody>
              </AccordionItem>
            );
          })
        ) : (
          <div className="alert alert-warning">
            <Translate contentKey="iamdentityApp.application.home.notFound">Not found</Translate>
          </div>
        )}
      </UncontrolledAccordion>

      <ErrorBoundaryRoutes>
        <Route path="removeUser/:userIdentityId">
          <Route index element={<EmployeeRemoveUserDialog />} />
        </Route>

        <Route
          path="modifyUserData/:userIdentityId"
          element={
            <InteractionDrawer>
              <ModifyUserData />
            </InteractionDrawer>
          }
        />
      </ErrorBoundaryRoutes>
    </React.Fragment>
  );
};

export default AccessRights;
