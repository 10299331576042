import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, Card, Col, Form, FormGroup, Label, Row, Spinner } from 'reactstrap';
import ValidatedInput from 'app/shared/components/validated-input';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import { addCustomPropertiesToCustomAttributesMap, extractCustomAttributesAsEntityProperties } from 'app/shared/util/entity-utils';

import { APP_GLOBAL_DATE_FORMAT } from 'app/config/constants';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { renderCustomAttributeField } from 'app/shared/util/component-utils';
import moment from 'moment';
import { useRef } from 'react';
import { createEntity, getEntity, resetEntity, updateEntity } from './permission-definition.reducer';
import { buildSearchParamsObject } from 'app/shared/util/url-utils';
import { useForm } from 'react-hook-form';
import PaginatedReactSelect from 'app/shared/components/react-select/paginated-react-select';
import { apiUrl as applicationResourceUrl } from '../application-resource/application-resource.reducer';
import { IApplicationResource } from 'app/shared/model/application-resource.model';
import { IPermissionDefinition } from 'app/shared/model/permission-definition.model';
import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { convertDateFormat } from 'app/shared/util/date-utils';
import ro from 'date-fns/locale/ro';
import navigateBack from 'app/shared/components/handlers/buttons/back';

export const PermissionDefinitionUpdate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const locale = useAppSelector(state => state.locale.currentLocale);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchParamsObject, setSearchParamsObject] = useState(buildSearchParamsObject(searchParams));

  const { permissionDefinitionUpdateId: id } = useParams<'permissionDefinitionUpdateId'>();
  const isNew = id === undefined;

  const permissionDefinitionEntity: IPermissionDefinition = useAppSelector(state => state.permissionDefinition.entity);
  const loading = useAppSelector(state => state.permissionDefinition.loading);
  const updating = useAppSelector(state => state.permissionDefinition.updating);
  const updateSuccess = useAppSelector(state => state.permissionDefinition.updateSuccess);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const startDate = useRef('');
  const handleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(resetEntity());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.PERMISSION_DEFINITION));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    formReset(defaultValues());
  }, [permissionDefinitionEntity, customAttributeDefinitions]);

  const saveEntity = values => {
    const entity = {
      ...addCustomPropertiesToCustomAttributesMap(permissionDefinitionEntity, values, customAttributeDefinitions),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () => {
    const result = isNew
      ? {
          startDate: null,
          endDate: null,
          // applicationResource: searchParamsObject['application'] ? searchParamsObject['application'].id : ''
        }
      : {
          ...extractCustomAttributesAsEntityProperties(customAttributeDefinitions, permissionDefinitionEntity),
        };
    startDate.current = result?.startDate;
    return result;
  };

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    reset: formReset,
  } = useForm({
    defaultValues: defaultValues(),
    mode: 'onChange',
  });

  useEffect(() => {
    setValue('applicationResource', null);
  }, []);

  const watchApplicationResource: IApplicationResource = watch('applicationResource');
  const watchStartDate: string = watch('startDate');
  const watchEndDate: string = watch('endDate');

  useEffect(() => {
    if (watchApplicationResource) {
      if (watchApplicationResource?.id !== permissionDefinitionEntity?.applicationResource?.id) {
        setValue('startDate', watchApplicationResource?.startDate);
        setValue('endDate', watchApplicationResource?.endDate);
      } else {
        setValue(
          'startDate',
          permissionDefinitionEntity?.startDate ? permissionDefinitionEntity?.startDate : watchApplicationResource?.startDate
        );
        setValue('endDate', permissionDefinitionEntity?.endDate ? permissionDefinitionEntity?.endDate : watchApplicationResource?.endDate);
      }
    } else {
      setValue('startDate', null);
      setValue('endDate', null);
    }
  }, [watchApplicationResource]);

  const minEndDate = () => {
    if (watchApplicationResource?.startDate && watchApplicationResource?.startDate >= watchStartDate) {
      return moment(watchApplicationResource.startDate).toDate();
    }

    return moment(watchStartDate).toDate();
  };

  return (
    <Card className="jh-card p-3">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.permissionDefinition.home.createOrEditLabel" data-cy="PermissionDefinitionCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.permissionDefinition.home.createOrEditLabel">
              Create or edit a PermissionDefinition
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading || updating ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <Form onSubmit={handleSubmit(saveEntity)}>
              {!isNew ? (
                <ValidatedField
                  register={register}
                  name="id"
                  required
                  readOnly
                  id="permission-definition-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedInput
                control={control}
                label={`*${translate('iamdentityApp.permissionDefinition.name')}`}
                id="permission-definition-name"
                name="name"
                data-cy="name"
                type="text"
                validate={{ required: true }}
              />
              <ValidatedInput
                control={control}
                label={`*${translate('iamdentityApp.permissionDefinition.code')}`}
                id="permission-definition-code"
                name="code"
                data-cy="code"
                type="text"
                validate={{ required: true }}
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.permissionDefinition.description')}
                id="permission-definition-description"
                name="description"
                data-cy="description"
                type="text"
              />
              <FormGroup>
                <Label>
                  *
                  <Translate contentKey="iamdentityApp.permissionDefinition.applicationResource" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="applicationResource"
                  isDisabled={!isNew ? true : false}
                  validate={{ required: true }}
                  url={applicationResourceUrl}
                  queryProps={{
                    name: 'name',
                    type: 'string',
                    predefinedQuery: `${
                      searchParamsObject['application'] ? 'applicationId.equals=' + searchParamsObject['application'].id : ''
                    }`,
                  }}
                />
              </FormGroup>
              <div className="date-picker-container date-picker-custom-content mb-3">
                <Controller
                  control={control}
                  name="startDate"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <div className="form-group">
                      <label htmlFor="permission-definition-startDate" className="form-label">
                        *{translate('iamdentityApp.permissionDefinition.startDate')}
                      </label>
                      <DatePicker
                        disabled={!watchApplicationResource ? true : false}
                        popperClassName="datepicker-calendar-popper"
                        minDate={
                          watchApplicationResource?.startDate
                            ? moment(watchApplicationResource?.startDate).toDate()
                            : moment(startDate?.current).toDate()
                        }
                        maxDate={
                          (watchEndDate ? moment(watchEndDate, 'YYYY-MM-DD').toDate() : null) ||
                          (watchApplicationResource?.endDate ? moment(watchApplicationResource?.endDate).toDate() : null)
                        }
                        portalId="root-portal"
                        selected={value ? new Date(value) : new Date()}
                        onChange={date => {
                          onChange(date);
                        }}
                        value={value ? moment(value).format(convertDateFormat(locale, 'date')) : convertDateFormat(locale, 'date')}
                        onBlur={onBlur}
                        locale={locale === 'ro' ? ro : 'en'}
                        todayButton={<Translate contentKey="global.today">Today</Translate>}
                        showMonthDropdown
                        showYearDropdown
                        className="form-control"
                        ref={ref}
                        id="permission-definition-startDate"
                        data-cy="startDate"
                      />
                    </div>
                  )}
                />
              </div>
              <div className="date-picker-container date-picker-custom-content mb-3">
                <Controller
                  control={control}
                  name="endDate"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <div className="form-group">
                      <label htmlFor="permission-definition-endDate" className="form-label">
                        {translate('iamdentityApp.permissionDefinition.endDate')}
                      </label>
                      <div className="position-relative">
                        <DatePicker
                          disabled={!watchApplicationResource ? true : false}
                          popperClassName="datepicker-calendar-popper"
                          minDate={minEndDate()}
                          maxDate={watchApplicationResource?.endDate ? moment(watchApplicationResource?.endDate).toDate() : null}
                          portalId="root-portal"
                          selected={value ? new Date(value) : new Date()}
                          onChange={date => {
                            onChange(date);
                          }}
                          value={value ? moment(value).format(convertDateFormat(locale, 'date')) : convertDateFormat(locale, 'date')}
                          onBlur={onBlur}
                          locale={locale === 'ro' ? ro : 'en'}
                          todayButton={<Translate contentKey="global.today">Today</Translate>}
                          showMonthDropdown
                          showYearDropdown
                          className="form-control"
                          ref={ref}
                          id="permission-definition-endDate"
                          data-cy="endDate"
                        />
                        <span
                          onClick={() => {
                            onChange(null);
                          }}
                          className="fw-semibold close-icon-custom"
                        >
                          {value !== null && value !== convertDateFormat(locale, 'date') && watchApplicationResource?.endDate === null
                            ? 'X'
                            : ''}
                        </span>
                      </div>
                    </div>
                  )}
                />
              </div>
              {customAttributeDefinitions?.map(cad =>
                renderCustomAttributeField({
                  control: control,
                  register,
                  locale: locale,
                  options: cad.selectableOptions,
                  id: `permission-definition-${cad.displayName}`,
                  name: cad.displayName,
                  label: cad.displayName,
                  'data-cy': cad.displayName,
                  attributeType: cad.customAttributeType,
                })
              )}
              <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={navigateBack} color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </Form>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default PermissionDefinitionUpdate;
