import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate, translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { AuditEntityName } from 'app/shared/model/audit.model';
import AuditModal from '../audit/audit-modal';
import { getEntity } from './iam-system.reducer';
import { convertDateFormat } from 'app/shared/util/date-utils';
import navigateBack from 'app/shared/components/handlers/buttons/back';

export const IAMSystemDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [auditModal, setAuditModal] = useState<boolean>(false);

  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const iAMSystemEntity = useAppSelector(state => state.iAMSystem.entity);
  const locale = useAppSelector(state => state.locale.currentLocale);

  return (
    <React.Fragment>
      <Row>
        <Col md="3">
          <Card className="jh-card card-grey p-2">
            <h2 data-cy="iAMSystemDetailsHeading">
              <Translate contentKey="iamdentityApp.iAMSystem.detail.title">IAMSystem</Translate>
            </h2>
            <dl className="jh-entity-details">
              <dt>
                <span id="id">
                  <Translate contentKey="global.field.id">ID</Translate>
                </span>
              </dt>
              <dd>{iAMSystemEntity.id}</dd>
              <dt>
                <span id="name">
                  <Translate contentKey="iamdentityApp.iAMSystem.name">Name</Translate>
                </span>
              </dt>
              <dd>{iAMSystemEntity.name}</dd>
              <dt>
                <span id="code">
                  <Translate contentKey="iamdentityApp.iAMSystem.code">Code</Translate>
                </span>
              </dt>
              <dd>{iAMSystemEntity.code}</dd>
              <dt>
                <span id="description">
                  <Translate contentKey="iamdentityApp.iAMSystem.description">Description</Translate>
                </span>
              </dt>
              <dd>{iAMSystemEntity.description}</dd>
              <dt>
                <span id="startDate">
                  <Translate contentKey="iamdentityApp.iAMSystem.startDate">Start Date</Translate>
                </span>
              </dt>
              <dd>
                {iAMSystemEntity.startDate ? (
                  <TextFormat value={iAMSystemEntity.startDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
              <dt>
                <span id="endDate">
                  <Translate contentKey="iamdentityApp.iAMSystem.endDate">End Date</Translate>
                </span>
              </dt>
              <dd>
                {iAMSystemEntity.endDate ? (
                  <TextFormat value={iAMSystemEntity.endDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
              <dt>
                <Translate contentKey="iamdentityApp.iAMSystem.connectorType">Connector Type</Translate>
              </dt>
              <dd>{iAMSystemEntity.connectorType ? iAMSystemEntity.connectorType.name : ''}</dd>
              <dt>
                <span id="userOnSystem">
                  <Translate contentKey="iamdentityApp.iAMSystem.userOnSystem">User On System</Translate>
                </span>
              </dt>
              <dd>{iAMSystemEntity.userOnSystem ? translate('global.yes') : translate('global.no')}</dd>
              <dt>
                <Translate contentKey="global.field.createdBy">Created By</Translate>
              </dt>
              <dd>{iAMSystemEntity.createdBy ? iAMSystemEntity.createdBy : ''}</dd>
              <dt>
                <Translate contentKey="global.field.createdDate">Created Date</Translate>
              </dt>
              <dd>
                {iAMSystemEntity.createdDate ? (
                  <TextFormat value={iAMSystemEntity.createdDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
              <dt>
                <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
              </dt>
              <dd>{iAMSystemEntity.lastModifiedBy ? iAMSystemEntity.lastModifiedBy : ''}</dd>
              <dt>
                <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
              </dt>
              <dd>
                {iAMSystemEntity.lastModifiedDate ? (
                  <TextFormat value={iAMSystemEntity.lastModifiedDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
            </dl>
            <div className="btn-group flex-btn-group-container">
              <Button id="cancel-save" data-cy="entityDetailsBackButton" onClick={navigateBack} color="info">
                <FontAwesomeIcon icon="arrow-left" />{' '}
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              <Button tag={Link} to={`/iam-system/${iAMSystemEntity.id}/edit`} color="warning">
                <FontAwesomeIcon icon="pencil-alt" />{' '}
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.edit">Edit</Translate>
                </span>
              </Button>
              <Button onClick={toggleAuditModal} color="light">
                <FontAwesomeIcon icon={faClockRotateLeft} />{' '}
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.history">History</Translate>
                </span>
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
      <AuditModal isOpen={auditModal} toggle={toggleAuditModal} entityId={id} entityName={AuditEntityName.IAM_SYSTEM} />
    </React.Fragment>
  );
};

export default IAMSystemDetail;
