import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_DATE_FORMAT, APP_TABLE_TEXT_MAX_CHARS } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import CompactExpressionBuilder from 'app/shared/components/expression-builder/compact-expression-builder';
import PaginatedDropdown from 'app/shared/components/pagination-dropdown';
import Filter from 'app/shared/FilterComponent';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import {
  findCustomAttributeValueByKey,
  getFiltersForCustomAttributes,
  overridePaginationStateWithQueryParams,
} from 'app/shared/util/entity-utils';
import { getQueryParamsIfExists } from 'app/shared/util/filter-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { getSortState, JhiItemCount, JhiPagination, TextFormat, translate, Translate } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, Table, UncontrolledTooltip } from 'reactstrap';
import { exportEntities, fetchEntityList, searchEntities } from './profile-specific-permission.reducer';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { handleSortIconsTable } from 'app/shared/util/table-icons';
import ShowCustomAttributes from 'app/shared/components/customButtons/showCustomAttributes';
import CustomText from 'app/shared/components/customText/customText';
import { AuditEntityName } from 'app/shared/model/audit.model';
import AuditEntityModal from '../audit/audit-entity-modal';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import AuditModal from '../audit/audit-modal';

export const ProfileSpecificPermission = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const [auditModal, setAuditModal] = useState<boolean>(false);
  const [auditModal2, setAuditModal2] = useState<boolean>(false);
  const [entityId, setEntityId] = useState<string>('');

  const [search, setSearch] = useState('');

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const handlePageSizeChange = size => {
    paginationState.activePage = 1;
    paginationState.itemsPerPage = size;
    dispatch(
      fetchEntityList({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query,
      })
    );
  };
  const [filterShown, setFilterShown] = useState<boolean>(true);
  const [query, setQuery] = useState<string>(getQueryParamsIfExists(location.search));
  const profileSpecificPermissionList = useAppSelector(state => state.profileSpecificPermission.entities);
  const loading = useAppSelector(state => state.profileSpecificPermission.loading || state.customAttributeDefinition.loading);
  const totalItems = useAppSelector(state => state.profileSpecificPermission.totalItems);
  const locale = useAppSelector(state => state.locale.currentLocale);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state =>
    state.customAttributeDefinition.entities?.filter(
      def => def.visibleInList && localStorage.getItem('TABLE_showCustomAttributes') === 'true'
    )
  );
  const toggleFilter = () => {
    if (filterShown) setQuery('');
    setFilterShown(!filterShown);
  };

  const getAllEntities = () => {
    if (search) {
      dispatch(
        searchEntities({
          query: search,
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        })
      );
    } else {
      dispatch(
        fetchEntityList({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query,
        })
      );
    }
  };

  const startSearching = e => {
    if (search) {
      setPaginationState({
        ...paginationState,
        activePage: 1,
      });
      dispatch(
        searchEntities({
          query: search,
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        })
      );
    }
    e.preventDefault();
  };

  const clear = () => {
    setSearch('');
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    dispatch(fetchEntityList({}));
  };

  const handleSearch = event => setSearch(event.target.value);

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}&query=${query}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };
  useEffect(() => {
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.PROFILE_SPECIFIC_PERMISSION));
  }, []);
  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, search, query]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };
  const exportTable = () => {
    dispatch(
      exportEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query,
      })
    );
  };

  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };

  const toggleAuditModal2 = (id?: string) => {
    if (id) {
      setEntityId(id);
    }
    setAuditModal(false);
    setAuditModal2(!auditModal2);
  };

  return (
    <Card className="jh-card h-100">
      <h2 id="profile-specific-permission-heading" data-cy="ProfileSpecificPermissionHeading">
        <Translate contentKey="iamdentityApp.profileSpecificPermission.home.title">Profile Specific Permissions</Translate>
        <div className="d-flex justify-content-end">
          <ShowCustomAttributes />
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />
            <Translate contentKey="iamdentityApp.profileSpecificPermission.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Button className="me-2" color="light" onClick={exportTable} disabled={loading}>
            <FontAwesomeIcon icon="file-excel" spin={loading} />
            <Translate contentKey="entity.action.export">Export</Translate>
          </Button>
          <Button onClick={toggleAuditModal} color="light" className="me-2">
            <FontAwesomeIcon icon={faClockRotateLeft} />
            <Translate contentKey="entity.action.history">History</Translate>
          </Button>
        </div>
      </h2>
      <div className="table-responsive table-scroll-custom h-100">
        <Table responsive>
          <thead className="table-scroll-custom_thead">
            {filterShown && (
              <Filter
                getEntities={setQuery}
                columns={[
                  {
                    name: 'id',
                    type: 'string',
                    placeholder: translate('iamdentityApp.profileSpecificPermission.id'),
                  },
                  {
                    name: 'profileDefinition',
                    type: 'string',
                    placeholder: translate('iamdentityApp.profileUserIdentityRole.profileDefinition'),
                  },
                  {
                    name: 'profileUserIdentity',
                    type: 'string',
                    placeholder: translate('iamdentityApp.profileSpecificPermission.profileUserIdentity'),
                  },
                  {
                    name: 'permissionDefinition',
                    type: 'string',
                    placeholder: translate('iamdentityApp.profileSpecificPermission.permissionDefinition'),
                  },
                  {
                    name: 'permissionDefinitionExpression',
                    type: 'string',
                    placeholder: translate('iamdentityApp.profileSpecificPermission.permissionDefinitionExpression'),
                  },
                  {
                    name: 'startDate',
                    type: 'instant',
                  },
                  {
                    name: 'endDate',
                    type: 'instant',
                  },
                  ...getFiltersForCustomAttributes(customAttributeDefinitions),
                ]}
              />
            )}
            <tr>
              <th className="hand id-size" onClick={sort('id')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.profileSpecificPermission.id">ID</Translate>
                  </CustomText>
                  {handleSortIconsTable('id')}
                </div>
              </th>
              <th className="hand" onClick={sort('profileUserIdentity.profileDefinition.name')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.profileUserIdentityRole.profileDefinition">Profile Definition</Translate>
                  </CustomText>
                  {handleSortIconsTable('profileUserIdentity.profileDefinition.name')}
                </div>
              </th>
              <th className="hand" onClick={sort('profileUserIdentity')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.profileSpecificPermission.profileUserIdentity">User on Profile</Translate>
                  </CustomText>
                  {handleSortIconsTable('profileUserIdentity')}
                </div>
              </th>
              <th className="hand" onClick={sort('permissionDefinition.name')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.profileSpecificPermission.permissionDefinition">Permission Definition</Translate>
                  </CustomText>
                  {handleSortIconsTable('permissionDefinition')}
                </div>
              </th>
              <th className="hand" onClick={sort('permissionDefinitionExpression')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.profileSpecificPermission.permissionDefinitionExpression">
                      Permission Definition Expression
                    </Translate>
                  </CustomText>
                  {handleSortIconsTable('permissionDefinitionExpression')}
                </div>
              </th>
              <th className="hand" onClick={sort('startDate')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.profileSpecificPermission.startDate">Start Date</Translate>
                  </CustomText>
                  {handleSortIconsTable('startDate')}
                </div>
              </th>
              <th className="hand" onClick={sort('endDate')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.profileSpecificPermission.endDate">End Date</Translate>
                  </CustomText>
                  {handleSortIconsTable('endDate')}
                </div>
              </th>
              {customAttributeDefinitions?.map(cad => (
                <th key={cad.id} className={'hand'}>
                  {cad.displayName}
                </th>
              ))}
              <th className="hand">
                {/*<span>*/}
                {/*  <Button size="sm" id="toggle-filter" color={filterShown ? 'secondary' : 'default'} onClick={toggleFilter}>*/}
                {/*    <FontAwesomeIcon icon="magnifying-glass" />*/}
                {/*  </Button>*/}
                {/*</span>*/}
                {/*<UncontrolledTooltip placement="top" target="toggle-filter">*/}
                {/*  <Translate contentKey={filterShown ? 'global.hideFilter' : 'global.showFilter'}>Show/Hide Filter</Translate>*/}
                {/*</UncontrolledTooltip>*/}
              </th>
            </tr>
          </thead>
          <tbody>
            {profileSpecificPermissionList.map((profileSpecificPermission, i) => (
              <tr key={`entity-${i}`} data-cy="entityTable">
                <td>
                  <Link to={`/profile-specific-permission/${profileSpecificPermission.id}`} color="link">
                    {profileSpecificPermission.id}
                  </Link>
                </td>
                <td>
                  {profileSpecificPermission.profileUserIdentity?.profileDefinition ? (
                    <Link to={`/profile-definition/${profileSpecificPermission.profileUserIdentity.profileDefinition.id}`}>
                      <CustomText>{profileSpecificPermission.profileUserIdentity.profileDefinition.name}</CustomText>
                    </Link>
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  {profileSpecificPermission.profileUserIdentity ? (
                    <Link to={`/profile-user-identity/${profileSpecificPermission.profileUserIdentity.id}`}>
                      <CustomText>
                        {profileSpecificPermission.profileUserIdentity?.profileDefinition?.name} -
                        {profileSpecificPermission.profileUserIdentity?.application?.name}
                      </CustomText>
                    </Link>
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  {profileSpecificPermission.permissionDefinition ? (
                    <Link to={`/permission-definition/${profileSpecificPermission.permissionDefinition.id}`}>
                      <CustomText>{profileSpecificPermission.permissionDefinition.name}</CustomText>
                    </Link>
                  ) : (
                    ''
                  )}
                </td>
                <td id={`permissionDefinitionExpression-${i}`}>
                  {profileSpecificPermission.permissionDefinitionExpression ? (
                    <UncontrolledTooltip placement="right" target={`permissionDefinitionExpression-${i}`}>
                      <CompactExpressionBuilder tree={profileSpecificPermission.permissionDefinitionExpression} />
                    </UncontrolledTooltip>
                  ) : null}
                </td>
                <td>
                  {profileSpecificPermission.startDate ? (
                    <TextFormat type="date" value={profileSpecificPermission.startDate} format={convertDateFormat(locale, 'instant')} />
                  ) : null}
                </td>
                <td>
                  {profileSpecificPermission.endDate ? (
                    <TextFormat type="date" value={profileSpecificPermission.endDate} format={convertDateFormat(locale, 'instant')} />
                  ) : null}
                </td>
                {customAttributeDefinitions?.map(cad => (
                  <td key={cad.id}>
                    <CustomText>{findCustomAttributeValueByKey(cad, profileSpecificPermission, locale)}</CustomText>
                  </td>
                ))}
                <td className="text-end">
                  <div className="btn-group flex-btn-group-container">
                    <Button
                      tag={Link}
                      to={`/profile-specific-permission/${profileSpecificPermission.id}`}
                      color="info"
                      size="sm"
                      data-cy="entityDetailsButton"
                    >
                      <FontAwesomeIcon icon="eye" />
                      {/*<span className="d-none d-md-inline">*/}
                      {/*  <Translate contentKey="entity.action.view">View</Translate>*/}
                      {/*</span>*/}
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {(!profileSpecificPermissionList || profileSpecificPermissionList.length < 1) && !loading && (
          <div className="alert alert-warning">
            <Translate contentKey="iamdentityApp.profileSpecificPermission.home.notFound">No Profile Specific Permissions found</Translate>
          </div>
        )}
      </div>
      {totalItems ? (
        <div className={profileSpecificPermissionList && profileSpecificPermissionList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex mt-2">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
            <PaginatedDropdown itemsPerPage={paginationState.itemsPerPage} handlePageSizeChange={handlePageSizeChange}></PaginatedDropdown>
          </div>
        </div>
      ) : (
        ''
      )}
      <AuditEntityModal
        isOpen={auditModal}
        toggle={toggleAuditModal}
        entityName={AuditEntityName.PROFILE_SPECIFIC_PERMISSION}
        toggleAuditModal2={toggleAuditModal2}
        entityFieldName={'startDate'}
        entityDetailsName={'profileSpecificPermission'}
        entityLink={'profile-specific-permission'}
      />
      <AuditModal
        isOpen={auditModal2}
        toggle={() => toggleAuditModal2()}
        entityId={entityId}
        entityName={AuditEntityName.PROFILE_SPECIFIC_PERMISSION}
      />
    </Card>
  );
};

export default ProfileSpecificPermission;
