import React from 'react';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

function navigateBack(): void {
  if (window.history.length > 1) {
    window.history.back();
  } else if (document.referrer) {
    window.location.href = document.referrer;
  } else {
    console.log('[BUTTON-BACK] No navigation history or referrer available.');
  }
}

function Back() {
  return (
    <button onClick={navigateBack} className={'btn btn-info'}>
      <FontAwesomeIcon icon={faArrowLeft} />
      <Translate contentKey={'entity.action.back'} />
    </button>
  );
}

export default navigateBack;
