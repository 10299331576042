import dayjs from 'dayjs';
import { IInAdditionalAttribute } from './in-additional-attribute.model';

export interface IInOrganizationUnit {
  id?: number;
  name?: string | null;
  shortName?: string | null;
  validFrom?: string | null;
  validTo?: string | null;
  organizationUnitTypeName?: string | null;
  parentOrganizationUnitCode?: string | null;
  locationCode?: string | null;
  managerEmployeeCode?: string | null;
  additionalAttributes?: IInAdditionalAttribute | [];
}

export const defaultValue: Readonly<IInOrganizationUnit> = {};
