import React from 'react';

import Handler from 'app/shared/components/dataTransfer/handler/handler';

import './style.css';

export interface IDataTransfer {
  import?: true;
  export?: true;
  content?: {
    [key: string]: string;
  };
  setValue?: (any) => void;
}

export default function DataTransfer(params: IDataTransfer) {
  return (
    <div className={'data-transfer w-100'}>
      <Handler import={params.import} export={params.export} content={params.content} setValue={params.setValue} />
    </div>
  );
}
