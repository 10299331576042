import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Row, Col, Spinner } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { ITechnicalOrderAction } from 'app/shared/model/technical-order-action.model';
import { getEntities as getTechnicalOrderActions } from 'app/entities/technical-order-action/technical-order-action.reducer';
import { IConnectorCommandAttribute } from 'app/shared/model/connector-command-attribute.model';
import { getEntities as getConnectorCommandAttributes } from 'app/entities/connector-command-attribute/connector-command-attribute.reducer';
import { ITechnicalOrderActionAttribute } from 'app/shared/model/technical-order-action-attribute.model';
import { getEntity, updateEntity, createEntity, reset } from './technical-order-action-attribute.reducer';
import moment from 'moment';
import { useRef } from 'react';
import { APP_GLOBAL_DATE_FORMAT } from 'app/config/constants';
import navigateBack from 'app/shared/components/handlers/buttons/back';

export const TechnicalOrderActionAttributeUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const technicalOrderActions = useAppSelector(state => state.technicalOrderAction.entities);
  const connectorCommandAttributes = useAppSelector(state => state.connectorCommandAttribute.entities);
  const technicalOrderActionAttributeEntity = useAppSelector(state => state.technicalOrderActionAttribute.entity);
  const loading = useAppSelector(state => state.technicalOrderActionAttribute.loading);
  const updating = useAppSelector(state => state.technicalOrderActionAttribute.updating);
  const updateSuccess = useAppSelector(state => state.technicalOrderActionAttribute.updateSuccess);

  const handleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getTechnicalOrderActions({}));
    dispatch(getConnectorCommandAttributes({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...technicalOrderActionAttributeEntity,
      ...values,
      technicalOrderAction: technicalOrderActions.find(it => it.id.toString() === values.technicalOrderAction.toString()),
      attribute: connectorCommandAttributes.find(it => it.id.toString() === values.attribute.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...technicalOrderActionAttributeEntity,
          technicalOrderAction: technicalOrderActionAttributeEntity?.technicalOrderAction?.id,
          attribute: technicalOrderActionAttributeEntity?.attribute?.id,
        };

  return (
    <Card className="jh-card">
      <Row className="justify-content-center">
        <Col md="8">
          <h2
            id="iamdentityApp.technicalOrderActionAttribute.home.createOrEditLabel"
            data-cy="TechnicalOrderActionAttributeCreateUpdateHeading"
          >
            <Translate contentKey="iamdentityApp.technicalOrderActionAttribute.home.createOrEditLabel">
              Create or edit a TechnicalOrderActionAttribute
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading || updating ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="technical-order-action-attribute-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('iamdentityApp.technicalOrderActionAttribute.value')}
                id="technical-order-action-attribute-value"
                name="value"
                data-cy="value"
                type="text"
              />
              <ValidatedField
                id="technical-order-action-attribute-technicalOrderAction"
                name="technicalOrderAction"
                data-cy="technicalOrderAction"
                label={translate('iamdentityApp.technicalOrderActionAttribute.technicalOrderAction')}
                type="select"
              >
                <option value="" key="0">
                  {translate('global.select')}
                </option>
                {technicalOrderActions
                  ? technicalOrderActions.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="technical-order-action-attribute-attribute"
                name="attribute"
                data-cy="attribute"
                label={translate('iamdentityApp.technicalOrderActionAttribute.attribute')}
                type="select"
              >
                <option value="" key="0">
                  {translate('global.select')}
                </option>
                {connectorCommandAttributes
                  ? connectorCommandAttributes.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <div className="mt-1">
                <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={navigateBack} color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </div>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default TechnicalOrderActionAttributeUpdate;
