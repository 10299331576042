import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef } from 'react';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Form, FormGroup, Label, Row, Spinner } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { AUTHORITIES } from 'app/config/constants';
import {
  getEntitiesByObjectType as getCustomPropertiesByObjectType,
  reset as resetCA,
} from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import { apiUrl as employeesUrl, createEntity, updateEntity } from 'app/entities/employee/employee.reducer';
import { apiUrl as jobDefinitionsUrl } from 'app/entities/job-definition/job-definition.reducer';
import { apiUrl as locationsUrl } from 'app/entities/location/location.reducer';
import { apiUrl as organizationUnitsUrl } from 'app/entities/organization-unit/organization-unit.reducer';
import { apiUrl as peopleUrl } from 'app/entities/person/person.reducer';
import PaginatedReactSelect from 'app/shared/components/react-select/paginated-react-select';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { IEmployee } from 'app/shared/model/employee.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { EmployeeStatus } from 'app/shared/model/enumerations/employee-status.model';
import { renderCustomAttributeField } from 'app/shared/util/component-utils';
import {
  addCustomPropertiesToCustomAttributesMap,
  convertReactSelectValuesToEntityIds,
  extractCustomAttributesAsEntityProperties,
} from 'app/shared/util/entity-utils';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { getEntity, reset } from './employee.reducer';
import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { convertDateFormat } from 'app/shared/util/date-utils';
import ro from 'date-fns/locale/ro';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { getApiConfigurations } from 'app/modules/administration/administration.reducer';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import navigateBack from 'app/shared/components/handlers/buttons/back';
import ValidatedInput from 'app/shared/components/validated-input';

export const EmployeeUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const employeeEntity: IEmployee = useAppSelector(state => state.employee.entity);
  const loading = useAppSelector(state => state.employee.loading);
  const updating = useAppSelector(state => state.employee.updating);
  const updateSuccess = useAppSelector(state => state.employee.updateSuccess);
  const configuration = useAppSelector(state => state.administration.configuration);
  const allowManualEntityCreationProp = configuration?.apiConfigProps?.allowManualEntityCreation;
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const isHRAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.HR_ADMIN]));

  useEffect(() => {
    dispatch(getApiConfigurations());
  }, []);

  useEffect(() => {
    if (allowManualEntityCreationProp !== undefined && (!(isAdmin || isHRAdmin) || !allowManualEntityCreationProp)) {
      toast.error(translate('error.unauthorizedMessage'));
      navigate('/');
    }
  }, [isAdmin, isHRAdmin, allowManualEntityCreationProp, navigate]);

  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const employeeStatusValues = Object.keys(EmployeeStatus);
  const startDate = useRef('');
  const handleClose = () => {
    navigate(-1);
  };

  const locale = useAppSelector(state => state.locale.currentLocale);

  const defaultValues = () => {
    const result = isNew
      ? {
          contractStartDate: moment(new Date(), convertDateFormat(locale, 'date')).toDate(),
          contractEndDate: null,
        }
      : {
          employeeStatus: 'ACTIVE',
          ...extractCustomAttributesAsEntityProperties(customAttributeDefinitions, employeeEntity),
        };
    startDate.current = result?.startDate;
    return result;
  };

  const {
    register,
    handleSubmit,
    control,
    reset: formReset,
    watch,
  } = useForm({
    defaultValues: defaultValues(),
    mode: 'onChange',
  });

  const { ref: contractStartDateRef, ...contractStartDateProps } = register('contractStartDate', { required: true });
  const { ref: employeeStatusRef, ...employeeStatusProps } = register('employeeStatus', { required: true });
  const { ref: organizationUnitRef, ...organizationUnitProps } = register('organizationUnit', { required: true });
  const { ref: jobRef, ...jobProps } = register('job', { required: true });
  const { ref: superiorRef, ...superiorProps } = register('superior', { required: true });
  const { ref: locationRef, ...locationProps } = register('location', { required: true });
  const { ref: personRef, ...personProps } = register('person', { required: true });

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    dispatch(resetCA());
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.EMPLOYEE));
  }, [id]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    formReset(defaultValues());
  }, [employeeEntity, customAttributeDefinitions]);

  const saveEntity = values => {
    const entity = {
      ...addCustomPropertiesToCustomAttributesMap(employeeEntity, convertReactSelectValuesToEntityIds(values), customAttributeDefinitions),
      contractStartDate: values.contractStartDate ? moment(values.contractStartDate).format('YYYY-MM-DD') : null, // Format contractStartDate for server
      contractEndDate: values.contractEndDate ? moment(values.contractEndDate).format('YYYY-MM-DD') : null, // Format contractEndDate for server
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const watchContractStartDate = watch('contractStartDate');
  const watchContractEndDate = watch('contractEndDate');

  return (
    <Card className="jh-card">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.employee.home.createOrEditLabel" data-cy="EmployeeCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.employee.home.createOrEditLabel">Create or edit a Employee</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading || updating ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <Form onSubmit={handleSubmit(saveEntity)}>
              {!isNew ? (
                <React.Fragment>
                  <Label>
                    *<Translate contentKey="global.field.id" />
                  </Label>
                  <ValidatedField
                    register={register}
                    name="id"
                    required
                    disabled={isNew ? false : true}
                    id="employee-id"
                    validate={{ required: true }}
                  />
                </React.Fragment>
              ) : null}
              <ValidatedInput
                control={control}
                label={'*' + translate('iamdentityApp.employee.code')}
                id="employee-code"
                name="code"
                data-cy="code"
                type="text"
                validate={{ required: true }}
              />
              <div className="date-picker-container mb-3">
                <Controller
                  control={control}
                  name="contractStartDate"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <div className="form-group">
                      <label htmlFor="employee-contractStartDate" className="form-label">
                        *{translate('iamdentityApp.employee.contractStartDate')}
                      </label>
                      <DatePicker
                        maxDate={watchContractEndDate ? moment(watchContractEndDate, 'YYYY-MM-DD').toDate() : null}
                        selected={value ? new Date(value) : new Date()}
                        onChange={date => {
                          onChange(date ? moment(date, convertDateFormat(locale, 'date')).toDate() : null);
                        }}
                        value={value ? moment(value).format(convertDateFormat(locale, 'date')) : convertDateFormat(locale, 'date')}
                        onBlur={onBlur}
                        locale={locale === 'ro' ? ro : 'en'}
                        todayButton={<Translate contentKey="global.today">Today</Translate>}
                        showMonthDropdown
                        showYearDropdown
                        className="form-control"
                        ref={ref}
                        id="employee-contractStartDate"
                        data-cy="contractStartDate"
                      />
                    </div>
                  )}
                />
              </div>
              <div className="date-picker-container mb-3">
                <Controller
                  control={control}
                  name="contractEndDate"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <div className="form-group">
                      <label htmlFor="employee-contractEndDate" className="form-label">
                        {translate('iamdentityApp.employee.contractEndDate')}
                      </label>
                      <div className="position-relative">
                        <DatePicker
                          minDate={watchContractStartDate ? moment(watchContractStartDate, 'YYYY-MM-DD').toDate() : null}
                          selected={value ? new Date(value) : new Date()}
                          onChange={date => {
                            onChange(date ? moment(date, convertDateFormat(locale, 'date')).toDate() : null);
                          }}
                          value={value ? moment(value).format(convertDateFormat(locale, 'date')) : convertDateFormat(locale, 'date')}
                          onBlur={onBlur}
                          locale={locale === 'ro' ? ro : 'en'}
                          todayButton={<Translate contentKey="global.today">Today</Translate>}
                          showMonthDropdown
                          showYearDropdown
                          className="form-control"
                          ref={ref}
                          id="employee-contractEndDate"
                          data-cy="contractEndDate"
                        />
                        <span
                          onClick={() => {
                            onChange(null);
                          }}
                          className={`fw-semibold close-icon-custom ${!isNew ? 'pe-none' : ''}`}
                        >
                          {value !== null && value !== convertDateFormat(locale, 'date') ? 'X' : ''}
                        </span>
                      </div>
                    </div>
                  )}
                />
              </div>
              <Label>
                *<Translate contentKey="iamdentityApp.employee.employeeStatus" />
              </Label>
              <ValidatedField
                register={register}
                id="employee-employeeStatus"
                name="employeeStatus"
                //disabled={isNew ? false : true}
                data-cy="employeeStatus"
                type="select"
              >
                {isNew
                  ? employeeStatusValues
                      .filter(employeeStatus => employeeStatus === 'ACTIVE')
                      .map(employeeStatus => (
                        <option value={employeeStatus} key={employeeStatus}>
                          {translate('iamdentityApp.EmployeeStatus.' + employeeStatus)}
                        </option>
                      ))
                  : employeeStatusValues.map(employeeStatus => (
                      <option value={employeeStatus} key={employeeStatus}>
                        {translate('iamdentityApp.EmployeeStatus.' + employeeStatus)}
                      </option>
                    ))}
              </ValidatedField>
              <FormGroup>
                <Label>
                  *<Translate contentKey="iamdentityApp.employee.organizationUnit" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="organizationUnit"
                  url={organizationUnitsUrl}
                  queryProps={{
                    name: 'name',
                    type: 'string',
                  }}
                  //isDisabled={isNew ? false : true}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  *<Translate contentKey="iamdentityApp.employee.job" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="job"
                  url={jobDefinitionsUrl}
                  queryProps={{
                    name: 'name',
                    type: 'string',
                  }}
                  //isDisabled={isNew ? false : true}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  *<Translate contentKey="iamdentityApp.employee.superior" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="superior"
                  url={employeesUrl}
                  queryProps={{
                    name: 'code',
                    secondName: 'fullName',
                    type: 'string',
                    criteria: 'nameWithCode',
                  }}
                  //isDisabled={isNew ? false : true}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  *<Translate contentKey="iamdentityApp.employee.location" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="location"
                  url={locationsUrl}
                  queryProps={{
                    name: 'name',
                    type: 'string',
                  }}
                  //isDisabled={isNew ? false : true}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  *<Translate contentKey="iamdentityApp.employee.person" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="person"
                  url={peopleUrl}
                  queryProps={{
                    name: 'firstName',
                    secondName: 'name',
                    type: 'string',
                  }}
                  //isDisabled={isNew ? false : true}
                />
              </FormGroup>
              {customAttributeDefinitions?.map(cad =>
                renderCustomAttributeField({
                  control: control,
                  register: register,
                  locale: locale,
                  options: cad.selectableOptions,
                  id: `employee-${cad.displayName}`,
                  name: cad.displayName,
                  label: cad.displayName,
                  'data-cy': cad.displayName,
                  attributeType: cad.customAttributeType,
                })
              )}
              <div className="mt-1">
                <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={navigateBack} color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </div>
            </Form>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default EmployeeUpdate;
