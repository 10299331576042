import React from 'react';
import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import navigateBack from 'app/shared/components/handlers/buttons/back';

interface IProfileInteractionModal {
  children?: any;
}

function ProfileInteractionModal({ children }: IProfileInteractionModal) {
  const navigate = useNavigate();
  const back = navigateBack;

  return ReactDOM.createPortal(
    <Modal fullscreen isOpen={true} toggle={back}>
      <ModalHeader toggle={back} />
      <ModalBody>{children}</ModalBody>
    </Modal>,
    document.querySelector('body')
  );
}

export default ProfileInteractionModal;
