export enum OperationType {
  ADD = 'ADD',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  SUSPEND = 'SUSPEND',
  RESUME = 'RESUME',
  NONE = 'NONE',
  ADD_EXCEPTION = 'ADD_EXCEPTION',
  DELETE_EXCEPTION = 'DELETE_EXCEPTION',
  ADD_RESUME = 'ADD_RESUME',
}
