import React, { useState } from 'react';
import { Translate } from 'react-jhipster';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImport, faFileExport, faClipboard } from '@fortawesome/free-solid-svg-icons';
import { IDataTransfer } from 'app/shared/components/dataTransfer/dataTransfer';

export default function Handler(params: IDataTransfer) {
  const [showModal, setShowModal] = useState(false);
  let fileReader;

  const exportTo = (to: 'clipboard' | 'file') => {
    if (to === 'clipboard') {
      navigator.clipboard.writeText(JSON.stringify(params.content, undefined, 4)).then(() => setShowModal(!showModal));
    } else {
      const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(JSON.stringify(params.content, undefined, 4))}`;
      const link = document.createElement('a');
      link.href = jsonString;
      link.download = 'expression-builder.json';
      link.click();
    }
  };

  const handleFileRead = () => {
    const content = fileReader.result;
    console.log(content);
    params.setValue(content);
    setShowModal(false);
  };

  const handleFileChosen = e => {
    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(e.target.files[0]);
  };

  const importFrom = (from: 'clipboard' | 'file') => {
    if (from === 'clipboard') {
      navigator.clipboard.readText().then(data => {
        params.setValue(data);
        setShowModal(false);
      });
    } else {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'application/json';
      input.onchange = handleFileChosen;
      input.click();
    }
  };

  return (
    <React.Fragment>
      <a className={'btn btn-info p-0 w-100'} onClick={() => setShowModal(!showModal)}>
        {handlerButton(params.export)}
      </a>
      <Modal isOpen={showModal}>
        <ModalHeader className={'pb-2'}>
          <h4 color={'white'}>
            <Translate contentKey={`iamdentityApp.dataTransfer.title.${params.export ? 'export' : 'import'}`} />
          </h4>
        </ModalHeader>
        <ModalBody>
          <Row className={'row-cols-1 gy-1 justify-content-between w-100'}>
            <Col>
              <Row className={'m-0'}>
                <Col md={2} className={'col-icon text-center p-0'}>
                  <FontAwesomeIcon icon={faClipboard} />
                </Col>
                <Col md={10} className={'align-content-center p-0'}>
                  <button
                    className={'btn btn-success p-1 w-100 h-100'}
                    onClick={() => (params.export ? exportTo('clipboard') : importFrom('clipboard'))}
                  >
                    <Translate
                      contentKey={`iamdentityApp.dataTransfer.title.${params.export ? 'exportToClipboard' : 'importFromClipboard'}`}
                    />
                  </button>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row className={'m-0'}>
                <Col md={2} className={'col-icon text-center p-0'}>
                  <FontAwesomeIcon icon={params.export ? faFileExport : faFileImport} />
                </Col>
                <Col md={10} className={'align-content-center p-0'}>
                  <button
                    className={'btn btn-success p-1 w-100 h-100'}
                    onClick={() => (params.export ? exportTo('file') : importFrom('file'))}
                  >
                    <Translate contentKey={`iamdentityApp.dataTransfer.title.${params.export ? 'exportToFile' : 'importFromFile'}`} />
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className={'p-2'}>
          <button className={'btn btn-primary px-2 pt-1'} onClick={() => setShowModal(!showModal)}>
            <Translate contentKey={`entity.action.close`} />
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

function handlerButton(isExport: boolean) {
  return (
    <Row className={'m-0'}>
      <Col md={3} className={'col-icon align-content-center'}>
        <FontAwesomeIcon icon={isExport ? faFileExport : faFileImport} />
      </Col>
      <Col md={9} className={'align-content-center'}>
        <Translate contentKey={`iamdentityApp.dataTransfer.title.${isExport ? 'export' : 'import'}`} />
      </Col>
    </Row>
  );
}
