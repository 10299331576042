import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

export const OrdersEmptyApplicationsDialog = ({ isOpen, setApplicationsWarningModalOpen }) => {
  const handleClose = () => {
    setApplicationsWarningModalOpen(false);
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader className="sticky-top">
        <Translate contentKey="iamdentityApp.orders.usersInApplicationsWarning">Users in Applications Warning</Translate>
      </ModalHeader>
      <ModalBody>
        <div className="alert alert-warning m-0">
          <span>
            <Translate contentKey="iamdentityApp.orders.noEligibleUsers">No eligible users found in any application!</Translate>
          </span>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleClose}>
          <FontAwesomeIcon icon={faCheck} />
          &nbsp; OK
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default OrdersEmptyApplicationsDialog;
