import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { TextFormat, Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { getEntity } from './import-session.reducer';
import navigateBack from 'app/shared/components/handlers/buttons/back';

export const ImportSessionDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const locale = useAppSelector(state => state.locale.currentLocale);
  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const importSessionEntity = useAppSelector(state => state.importSession.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="importSessionDetailsHeading">
          <Translate contentKey="iamdentityApp.importSession.detail.title">ImportSession</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{importSessionEntity.id}</dd>
          <dt>
            <span id="sourceDataIdentifier">
              <Translate contentKey="iamdentityApp.importSession.sourceDataIdentifier">Source Data Identifier</Translate>
            </span>
          </dt>
          <dd>{importSessionEntity.sourceDataIdentifier}</dd>
          <dt>
            <span id="recordsInFile">
              <Translate contentKey="iamdentityApp.importSession.recordsInFile">Records In File</Translate>
            </span>
          </dt>
          <dd>{importSessionEntity.recordsInFile}</dd>
          <dt>
            <span id="recordsWithError">
              <Translate contentKey="iamdentityApp.importSession.recordsWithError">Records With Error</Translate>
            </span>
          </dt>
          <dd>{importSessionEntity.recordsWithError}</dd>
          <dt>
            <span id="dateOfDataExport">
              <Translate contentKey="iamdentityApp.importSession.dateOfDataExport">Date Of Data Export</Translate>
            </span>
          </dt>
          <dd>
            {importSessionEntity.dateOfDataExport ? (
              <TextFormat value={importSessionEntity.dateOfDataExport} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="reconciliationPerformed">
              <Translate contentKey="iamdentityApp.importSession.reconciliationPerformed">Reconciliation Performed</Translate>
            </span>
          </dt>
          <dd>{importSessionEntity.reconciliationPerformed ? translate('global.yes') : translate('global.no')}</dd>
          <dt>
            <span id="reconciliationOrdersCreated">
              <Translate contentKey="iamdentityApp.importSession.reconciliationOrdersCreated">Reconciliation Orders Created</Translate>
            </span>
          </dt>
          <dd>{importSessionEntity.reconciliationOrdersCreated ? translate('global.yes') : translate('global.no')}</dd>
          <dt>
            <Translate contentKey="global.field.createdBy">Created By</Translate>
          </dt>
          <dd>{importSessionEntity.createdBy ? importSessionEntity.createdBy : ''}</dd>
          <dt>
            <Translate contentKey="global.field.createdDate">Created Date</Translate>
          </dt>
          <dd>
            {importSessionEntity.createdDate ? (
              <TextFormat value={importSessionEntity.createdDate} type="date" format={convertDateFormat(locale, 'date')} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
          </dt>
          <dd>{importSessionEntity.lastModifiedBy ? importSessionEntity.lastModifiedBy : ''}</dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
          </dt>
          <dd>
            {importSessionEntity.lastModifiedDate ? (
              <TextFormat value={importSessionEntity.lastModifiedDate} type="date" format={convertDateFormat(locale, 'date')} />
            ) : null}
          </dd>
        </dl>
        <Button id="cancel-save" color="info" onClick={navigateBack} data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ImportSessionDetail;
