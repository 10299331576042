import React from 'react';
import { Translate } from 'react-jhipster';

import MenuItem from 'app/shared/layout/menus/menu-item';
import { DropdownItem } from 'reactstrap';

const EntitiesMenu = () => {
  return (
    <>
      {/* prettier-ignore */}
      <MenuItem icon="asterisk" to="/my-tasks">
        <Translate contentKey="global.menu.entities.myTasks" />
      </MenuItem>

      <DropdownItem className="menu-header" header>
        <Translate contentKey="global.menu.hrData" />
      </DropdownItem>
      <MenuItem icon="asterisk" to="/employee">
        <Translate contentKey="global.menu.entities.employee" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/employee-delegation">
        <Translate contentKey="global.menu.entities.employeeDelegation" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/person">
        <Translate contentKey="global.menu.entities.person" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/organization-unit">
        <Translate contentKey="global.menu.entities.organizationUnit" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/job-definition">
        <Translate contentKey="global.menu.entities.jobDefinition" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/location">
        <Translate contentKey="global.menu.entities.location" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/organization-unit-type">
        <Translate contentKey="global.menu.entities.organizationUnitType" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/project">
        <Translate contentKey="global.menu.entities.project" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/project-assignment">
        <Translate contentKey="global.menu.entities.projectAssignment" />
      </MenuItem>

      <DropdownItem className="menu-header" header>
        <Translate contentKey="global.menu.usersAndRoles" />
      </DropdownItem>
      <MenuItem icon="asterisk" to="/user-identity">
        <Translate contentKey="global.menu.entities.userIdentity" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/user-group">
        <Translate contentKey="global.menu.entities.userGroup" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/user-identity-role">
        <Translate contentKey="global.menu.entities.userIdentityRole" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/specific-permission">
        <Translate contentKey="global.menu.entities.specificPermission" />
      </MenuItem>

      <DropdownItem className="menu-header" header>
        <Translate contentKey="global.menu.orders" />
      </DropdownItem>
      <MenuItem icon="asterisk" to="/orders">
        <Translate contentKey="global.menu.entities.orders" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/order-comments">
        <Translate contentKey="global.menu.entities.orderComments" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/ordered-user-identity">
        <Translate contentKey="global.menu.entities.orderedUserIdentity" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/ordered-role">
        <Translate contentKey="global.menu.entities.orderedRole" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/ordered-group">
        <Translate contentKey="global.menu.entities.orderedGroup" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/ordered-specific-permission">
        <Translate contentKey="global.menu.entities.orderedSpecificPermission" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/technical-order-action">
        <Translate contentKey="global.menu.entities.technicalOrderAction" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/technical-order-action-attribute">
        <Translate contentKey="global.menu.entities.technicalOrderActionAttribute" />
      </MenuItem>

      <DropdownItem className="menu-header" header>
        <Translate contentKey="global.menu.configuration" />
      </DropdownItem>
      <MenuItem icon="asterisk" to="/connector-type">
        <Translate contentKey="global.menu.entities.connectorType" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/iam-system">
        <Translate contentKey="global.menu.entities.iamSystem" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/application">
        <Translate contentKey="global.menu.entities.application" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/application-resource">
        <Translate contentKey="global.menu.entities.applicationResource" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/role-definition">
        <Translate contentKey="global.menu.entities.roleDefinition" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/role-group">
        <Translate contentKey="global.menu.entities.roleGroup" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/permission-definition">
        <Translate contentKey="global.menu.entities.permissionDefinition" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/custom-attribute-definition">
        <Translate contentKey="global.menu.entities.customAttributeDefinition" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/order-type">
        <Translate contentKey="global.menu.entities.orderType" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/order-approval-levels">
        <Translate contentKey="global.menu.entities.orderApprovalLevels" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/technical-order-action-create">
        <Translate contentKey="global.menu.entities.technicalOrderActionCreate" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/connector-command">
        <Translate contentKey="global.menu.entities.connectorCommand" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/connector-command-attribute">
        <Translate contentKey="global.menu.entities.connectorCommandAttribute" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/risk-rule">
        <Translate contentKey="global.menu.entities.riskRule" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/risk-combination">
        <Translate contentKey="global.menu.entities.riskCombination" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/email-template">
        <Translate contentKey="global.menu.entities.emailTemplate" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/import-session">
        <Translate contentKey="global.menu.entities.importSession" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/in-user-identity">
        <Translate contentKey="global.menu.entities.inUserIdentity" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/in-user-identity-role">
        <Translate contentKey="global.menu.entities.inUserIdentityRole" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/in-user-group">
        <Translate contentKey="global.menu.entities.inUserGroup" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/in-specific-permission">
        <Translate contentKey="global.menu.entities.inSpecificPermission" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/in-employee">
        <Translate contentKey="global.menu.entities.inEmployee" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/in-organization-unit">
        <Translate contentKey="global.menu.entities.inOrganizationUnit" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/in-location">
        <Translate contentKey="global.menu.entities.inLocation" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/in-employee-delegation">
        <Translate contentKey="global.menu.entities.inEmployeeDelegation" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/in-project">
        <Translate contentKey="global.menu.entities.inProject" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/in-project-assignment">
        <Translate contentKey="global.menu.entities.inProjectAssignment" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/in-job-definition">
        <Translate contentKey="global.menu.entities.inJobDefinition" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/in-additional-attribute">
        <Translate contentKey="global.menu.entities.inAdditionalAttribute" />
      </MenuItem>

      <DropdownItem className="menu-header" header>
        <Translate contentKey="global.menu.profiles" />
      </DropdownItem>
      <MenuItem icon="asterisk" to="/profile-definition">
        <Translate contentKey="global.menu.entities.profileDefinition" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/profile-user-identity">
        <Translate contentKey="global.menu.entities.profileUserIdentity" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/profile-user-identity-role">
        <Translate contentKey="global.menu.entities.profileUserIdentityRole" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/profile-user-group">
        <Translate contentKey="global.menu.entities.profileUserGroup" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/profile-specific-permission">
        <Translate contentKey="global.menu.entities.profileSpecificPermission" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/standard-policy-rule">
        <Translate contentKey="global.menu.entities.standardPolicyRule" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/recon-session-user-identity-iam-not-in-systems">
        <Translate contentKey="global.menu.entities.reconSessionUserIdentityIamNotInSystems" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/recon-session-user-identity-role-iam-not-in-systems">
        <Translate contentKey="global.menu.entities.reconSessionUserIdentityRoleIamNotInSystems" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/recon-session-user-group-iam-not-in-systems">
        <Translate contentKey="global.menu.entities.reconSessionUserGroupIamNotInSystems" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/recon-session-user-permission-iam-not-in-systems">
        <Translate contentKey="global.menu.entities.reconSessionUserPermissionIamNotInSystems" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/recon-session-user-identity-systems-not-in-iam">
        <Translate contentKey="global.menu.entities.reconSessionUserIdentitySystemsNotInIam" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/recon-session-user-identity-role-systems-not-in-iam">
        <Translate contentKey="global.menu.entities.reconSessionUserIdentityRoleSystemsNotInIam" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/recon-session-user-group-systems-not-in-iam">
        <Translate contentKey="global.menu.entities.reconSessionUserGroupSystemsNotInIam" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/recon-session-user-permission-systems-not-in-iam">
        <Translate contentKey="global.menu.entities.reconSessionUserPermissionSystemsNotInIam" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/recertification-session">
        <Translate contentKey="global.menu.entities.recertificationSession" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/recertification-session-user-identity">
        <Translate contentKey="global.menu.entities.recertificationSessionUserIdentity" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/recertification-session-user-role">
        <Translate contentKey="global.menu.entities.recertificationSessionUserRole" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/recertification-session-user-group">
        <Translate contentKey="global.menu.entities.recertificationSessionUserGroup" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/recertification-session-specific-permission">
        <Translate contentKey="global.menu.entities.recertificationSessionSpecificPermission" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/iamdentity-access-to-order-types">
        <Translate contentKey="global.menu.entities.iaMdentityAccessToOrderTypes" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/iam-imports">
        <Translate contentKey="global.menu.entities.iamImports" />
      </MenuItem>
      {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
    </>
  );
};

export default EntitiesMenu;
