import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { TextFormat, translate, Translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { AuditEntityName } from 'app/shared/model/audit.model';
import AuditModal from '../audit/audit-modal';
import { getEntity } from './employee-delegation.reducer';
import { convertDateFormat } from 'app/shared/util/date-utils';
import navigateBack from 'app/shared/components/handlers/buttons/back';

export const EmployeeDelegationDetail = () => {
  const dispatch = useAppDispatch();
  const [auditModal, setAuditModal] = useState<boolean>(false);
  const { id } = useParams<'id'>();

  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const employeeDelegationEntity = useAppSelector(state => state.employeeDelegation.entity);
  const locale = useAppSelector(state => state.locale.currentLocale);
  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };
  return (
    <React.Fragment>
      <Row>
        <Col md="3">
          <Card className="jh-card card-grey p-2">
            <h2 data-cy="employeeDelegationDetailsHeading">
              <Translate contentKey="iamdentityApp.employeeDelegation.detail.title">EmployeeDelegation</Translate>
            </h2>
            <dl className="jh-entity-details">
              <dt>
                <span id="id">
                  <Translate contentKey="global.field.id">ID</Translate>
                </span>
              </dt>
              <dd>{employeeDelegationEntity.id}</dd>
              <dt>
                <span id="validFrom">
                  <Translate contentKey="iamdentityApp.employeeDelegation.validFrom">Valid From</Translate>
                </span>
              </dt>
              <dd>
                {employeeDelegationEntity.validFrom ? (
                  <TextFormat value={employeeDelegationEntity.validFrom} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
              <dt>
                <span id="validTo">
                  <Translate contentKey="iamdentityApp.employeeDelegation.validTo">Valid To</Translate>
                </span>
              </dt>
              <dd>
                {employeeDelegationEntity.validTo ? (
                  <TextFormat value={employeeDelegationEntity.validTo} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
              <dt>
                <span id="cumulated">
                  <Translate contentKey="iamdentityApp.employeeDelegation.cumulated">Cumulated</Translate>
                </span>
              </dt>
              <dd>{employeeDelegationEntity.cumulated ? translate('global.yes') : translate('global.no')}</dd>
              <dt>
                <span id="status">
                  <Translate contentKey="iamdentityApp.employeeDelegation.status">Status</Translate>
                </span>
              </dt>
              <dd>{translate(`iamdentityApp.EmployeeDelegationStatus.${employeeDelegationEntity.status}`)}</dd>
              <dt>
                <Translate contentKey="iamdentityApp.employeeDelegation.employee">Employee</Translate>
              </dt>
              <dd>
                {employeeDelegationEntity.employee
                  ? employeeDelegationEntity.employee.fullName
                    ? employeeDelegationEntity.employee.code + ' - ' + employeeDelegationEntity.employee.fullName
                    : employeeDelegationEntity.employee.code
                  : ''}
              </dd>
              <dt>
                <Translate contentKey="iamdentityApp.employeeDelegation.job">Job</Translate>
              </dt>
              <dd>{employeeDelegationEntity.job ? employeeDelegationEntity.job.name : ''}</dd>
              <dt>
                <Translate contentKey="iamdentityApp.employeeDelegation.organizationUnit">Organization Unit</Translate>
              </dt>
              <dd>{employeeDelegationEntity.organizationUnit ? employeeDelegationEntity.organizationUnit.name : ''}</dd>
              <dt>
                <Translate contentKey="iamdentityApp.employeeDelegation.order">Order</Translate>
              </dt>
              <dd>{employeeDelegationEntity.order ? employeeDelegationEntity.order.id : ''}</dd>
              <dt>
                <Translate contentKey="global.field.createdBy">Created By</Translate>
              </dt>
              <dd>{employeeDelegationEntity.createdBy ? employeeDelegationEntity.createdBy : ''}</dd>
              <dt>
                <Translate contentKey="global.field.createdDate">Created Date</Translate>
              </dt>
              <dd>
                {employeeDelegationEntity.createdDate ? (
                  <TextFormat value={employeeDelegationEntity.createdDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
              <dt>
                <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
              </dt>
              <dd>{employeeDelegationEntity.lastModifiedBy ? employeeDelegationEntity.lastModifiedBy : ''}</dd>
              <dt>
                <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
              </dt>
              <dd>
                {employeeDelegationEntity.lastModifiedDate ? (
                  <TextFormat value={employeeDelegationEntity.lastModifiedDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
            </dl>
            <Button id="cancel-save" data-cy="entityDetailsBackButton" onClick={navigateBack} color="info">
              <FontAwesomeIcon icon="arrow-left" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.back">Back</Translate>
              </span>
            </Button>
            <Button onClick={toggleAuditModal} color="light">
              <FontAwesomeIcon icon={faClockRotateLeft} />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.history">History</Translate>
              </span>
            </Button>
          </Card>
        </Col>
      </Row>
      <AuditModal isOpen={auditModal} toggle={toggleAuditModal} entityId={id} entityName={AuditEntityName.EMPLOYEE_DELEGATION} />
    </React.Fragment>
  );
};

export default EmployeeDelegationDetail;
