import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Row, Col, Spinner, Form } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import ValidatedInput from 'app/shared/components/validated-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IConnectorType } from 'app/shared/model/connector-type.model';
import { getEntities as getConnectorTypes } from 'app/entities/connector-type/connector-type.reducer';
import { IConnectorCommand } from 'app/shared/model/connector-command.model';
import { getEntity, updateEntity, createEntity, reset } from './connector-command.reducer';
import moment from 'moment';
import { useRef } from 'react';
import { APP_GLOBAL_DATE_FORMAT } from 'app/config/constants';
import { useForm } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { convertDateFormat } from 'app/shared/util/date-utils';
import ro from 'date-fns/locale/ro';
import navigateBack from 'app/shared/components/handlers/buttons/back';

export const ConnectorCommandUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { connectorCommandDetailId: id } = useParams<'connectorCommandDetailId'>();
  const isNew = id === undefined;

  const connectorTypes = useAppSelector(state => state.connectorType.entities);
  const connectorCommandEntity = useAppSelector(state => state.connectorCommand.entity);
  const loading = useAppSelector(state => state.connectorCommand.loading);
  const updating = useAppSelector(state => state.connectorCommand.updating);
  const updateSuccess = useAppSelector(state => state.connectorCommand.updateSuccess);
  const locale = useAppSelector(state => state.locale.currentLocale);

  const handleClose = () => {
    navigate(-1);
  };

  const defaultValues = () => {
    const result = isNew
      ? { validFrom: moment().format(APP_GLOBAL_DATE_FORMAT) }
      : {
          ...connectorCommandEntity,
          connectorType: connectorCommandEntity?.connectorType?.id,
        };
    return result;
  };

  const {
    register,
    handleSubmit,
    control,
    reset: formReset,
    watch,
  } = useForm({
    defaultValues: defaultValues(),
    mode: 'onChange',
  });

  const { ref: validFromRef, ...validFromProps } = register('validFrom', { required: true });
  const { ref: validToRef, ...validToProps } = register('validTo');

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getConnectorTypes({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    formReset(defaultValues());
  }, [connectorCommandEntity]);

  const saveEntity = values => {
    const entity = {
      ...connectorCommandEntity,
      ...values,
      connectorType: connectorTypes.find(it => it.id.toString() === values.connectorType.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const validFrom = watch('validFrom');
  const validTo = watch('validTo');

  return (
    <Card className="jh-card">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.connectorCommand.home.createOrEditLabel" data-cy="ConnectorCommandCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.connectorCommand.home.createOrEditLabel">Create or edit a ConnectorCommand</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading || updating ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <Form onSubmit={handleSubmit(saveEntity)}>
              {!isNew ? (
                <ValidatedField
                  register={register}
                  name="id"
                  required
                  readOnly
                  id="connector-command-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedInput
                control={control}
                label={'*' + translate('iamdentityApp.connectorCommand.code')}
                id="connector-command-code"
                name="code"
                data-cy="code"
                type="text"
                validate={{ required: true }}
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.connectorCommand.description')}
                id="connector-command-description"
                name="description"
                data-cy="description"
                type="text"
              />
              <div className="date-picker-container mb-3">
                <Controller
                  control={control}
                  name="validFrom"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <div className="form-group">
                      <label htmlFor="connector-command-validFrom" className="form-label">
                        *{translate('iamdentityApp.connectorCommand.validFrom')}
                      </label>
                      <DatePicker
                        maxDate={validTo ? moment(validTo, 'YYYY-MM-DD').toDate() : null}
                        selected={value ? new Date(value) : new Date()}
                        onChange={date => {
                          onChange(date ? moment(date, convertDateFormat(locale, 'date')).toDate() : null);
                        }}
                        value={value ? moment(value).format(convertDateFormat(locale, 'date')) : convertDateFormat(locale, 'date')}
                        onBlur={onBlur}
                        locale={locale === 'ro' ? ro : 'en'}
                        todayButton={<Translate contentKey="global.today">Today</Translate>}
                        showMonthDropdown
                        showYearDropdown
                        className="form-control"
                        ref={validFromRef}
                        id="connector-command-validFrom"
                        data-cy="validFrom"
                      />
                    </div>
                  )}
                />
              </div>
              <div className="date-picker-container mb-3">
                <Controller
                  control={control}
                  name="validTo"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <div className="form-group">
                      <label htmlFor="connector-command-validTo" className="form-label">
                        {translate('iamdentityApp.connectorCommand.validTo')}
                      </label>
                      <DatePicker
                        minDate={validFrom ? moment(validFrom, 'YYYY-MM-DD').toDate() : null}
                        selected={value ? new Date(value) : new Date()}
                        onChange={date => {
                          onChange(date ? moment(date, convertDateFormat(locale, 'date')).toDate() : null);
                        }}
                        value={value ? moment(value).format(convertDateFormat(locale, 'date')) : convertDateFormat(locale, 'date')}
                        onBlur={onBlur}
                        locale={locale === 'ro' ? ro : 'en'}
                        todayButton={<Translate contentKey="global.today">Today</Translate>}
                        showMonthDropdown
                        showYearDropdown
                        className="form-control"
                        ref={validToRef}
                        id="connector-command-validTo"
                        data-cy="validTo"
                      />
                    </div>
                  )}
                />
              </div>
              <ValidatedField
                register={register}
                id="connector-command-connectorType"
                name="connectorType"
                data-cy="connectorType"
                label={translate('iamdentityApp.connectorCommand.connectorType')}
                type="select"
              >
                <option value="" key="0">
                  {translate('global.select')}
                </option>
                {connectorTypes
                  ? connectorTypes.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <div className="mt-1">
                <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={navigateBack} color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </div>
            </Form>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default ConnectorCommandUpdate;
