import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { translate } from 'react-jhipster';
import { Badge } from 'reactstrap';
import { Node, NodeBlock } from '../classes';
import { ExpressioNBuilderContext, renderNode } from '../expression-builder';
import '../style.scss';
import { DropdownClickProps, NodeType } from '../types';
import { newNodeByValueAndType } from '../utils';
import NodeComponent from './node.component';

interface NodeBlockComponentProps {
  node: NodeBlock;
}

const NodeBlockComponent = ({ node }: NodeBlockComponentProps) => {
  const { tree, setTree } = useContext(ExpressioNBuilderContext);

  const onAddClick = ({ value, type }: DropdownClickProps) => {
    if (type == NodeType._CASE) {
      const newNode = newNodeByValueAndType(value, type);
      tree.addChildAt(node, newNode, node.nodes.length - 1);
      setTree(tree);
    }
  };

  const onChangeClick = ({ value, type }: DropdownClickProps) => {
    const newNode = newNodeByValueAndType(value, type);
    newNode.acceptedValueTypes = node.acceptedValueTypes;
    newNode.expressionEntity = node.expressionEntity;
    newNode._type = node._type;
    tree.changeNode(node, newNode);
    setTree(tree);
  };

  const onRemoveClick = (n: Node) => {
    tree.removeChild(n);
    setTree(tree);
  };

  return (
    <React.Fragment>
      <div className="button-row">
        <NodeComponent onChange={onChangeClick} items={node.getAlternatives()} value={node.name} color="white">
          {translate(`iamdentityApp.expressionBuilder.${node.name}`)}
        </NodeComponent>
        {node?.valueType ? (
          <Badge className="mr-1" color="success">
            {node?.valueType}
          </Badge>
        ) : null}
        {node.type === NodeType._SWITCH ? <NodeComponent onChange={onAddClick} items={node.getChildItems(node.type)} type="add" /> : null}
      </div>
      <div className={`children-div ${node.type === NodeType._CASE ? 'case' : ''}`}>
        {node.nodes.map(child => {
          const showRemoveButton = [NodeType._CASE].includes(child.type);
          return (
            <div key={child.id} className="removable-row">
              {showRemoveButton ? (
                <div className="remove-button-area">
                  <FontAwesomeIcon className="remove-button" icon="xmark" size="lg" onClick={() => onRemoveClick(child)} />
                </div>
              ) : null}
              {renderNode(child)}
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default NodeBlockComponent;
